import { Button, TextField } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/core/styles'
import { CustomColor } from '@types'
import { useState } from 'react'
import { PhotoshopPicker } from 'react-color'

interface TextFieldProps {
  label: string
  value: string
  onChange: (val: string) => void
  isMultiline?: boolean
  large?: boolean
}

const ItemTextField = ({ label, value, onChange, large }: TextFieldProps) => {
  const classes = useStyles()

  return (
    <div className={large ? classes.largeTextField : classes.textField}>
      <TextField
        margin="dense"
        id={label}
        key={label}
        label={label}
        variant="standard"
        value={value}
        onChange={(e) => onChange(e.currentTarget.value)}
        style={{ marginBottom: 8 }}
      />
    </div>
  )
}

interface NumberFieldProps {
  label: string
  value: number
  onChange: (val: number) => void
  isMultiline?: boolean
  large?: boolean
}

const ItemNumberField = ({
  label,
  value,
  onChange,
  large,
}: NumberFieldProps) => {
  const classes = useStyles()

  return (
    <div className={large ? classes.largeTextField : classes.textField}>
      <TextField
        margin="dense"
        id={label + ''}
        key={label}
        label={label}
        variant="standard"
        value={value}
        onChange={(e) => onChange(parseInt(e.currentTarget.value) || 0)}
        style={{ marginBottom: 8 }}
      />
    </div>
  )
}

interface CustomColorRowProps {
  customColor: CustomColor
  setCustomColor: (customColor: CustomColor) => void
  onColorDelete?: () => void
  showColorPicker?: boolean
  showColorName?: boolean
}

const CustomColorRow = ({
  customColor,
  customColor: {
    name,
    hex,
    gain,
    dmx: { r, g, b, w, r_l, g_l, b_l, w_l },
  },
  setCustomColor,
  onColorDelete,
  showColorPicker = true,
  showColorName = true,
}: CustomColorRowProps) => {
  const [colorPickerActive, setColorPickerActive] = useState(false)
  const classes = useStyles()

  const setDMX = (key: keyof CustomColor['dmx']) => {
    return (value: number) =>
      setCustomColor({
        ...customColor,
        dmx: { ...customColor.dmx, [key]: value },
      })
  }

  return (
    <>
      <div className={classes.padding}>
        <div className={classes.root}>
          {showColorPicker && (
            <div
              onClick={() => setColorPickerActive(true)}
              className={classes.color}
              style={{ backgroundColor: hex }}
            />
          )}

          {showColorName && (
            <ItemTextField
              large
              label="Name"
              value={name}
              onChange={(e) =>
                setCustomColor({ ...customColor, name: e } as CustomColor)
              }
            />
          )}
          {showColorPicker && (
            <ItemTextField
              large
              label="Hex"
              value={hex}
              onChange={(e) =>
                setCustomColor({ ...customColor, hex: e } as CustomColor)
              }
            />
          )}
          <ItemNumberField
            label="Gain"
            value={gain}
            onChange={(e) =>
              setCustomColor({ ...customColor, gain: e } as CustomColor)
            }
          />

          <div className={classes.divider}>
            <p>RGBW at 100%:</p>
          </div>

          <div className={classes.colorWrapper}>
            <div
              className={classes.smallColor}
              style={{
                opacity: gain / 100,
                backgroundColor: `rgb(${Math.max(r, w)},${Math.max(
                  g,
                  w,
                )},${Math.max(b, w)})`,
              }}
            />
            <div
              className={classes.smallColor}
              style={{
                backgroundColor: `rgb(${Math.max(r, w)},${Math.max(
                  g,
                  w,
                )},${Math.max(b, w)})`,
              }}
            />
          </div>

          <ItemNumberField label="R" value={r} onChange={setDMX('r')} />
          <ItemNumberField label="G" value={g} onChange={setDMX('g')} />
          <ItemNumberField label="B" value={b} onChange={setDMX('b')} />
          <ItemNumberField label="W" value={w} onChange={setDMX('w')} />

          <div className={classes.colorWrapper}>
            <div
              className={classes.smallColor}
              style={{
                opacity: gain / 100,
                backgroundColor: `rgb(${Math.max(r_l, w_l)},${Math.max(
                  g_l,
                  w_l,
                )},${Math.max(b_l, w_l)})`,
              }}
            />
            <div
              className={classes.smallColor}
              style={{
                backgroundColor: `rgb(${Math.max(r_l, w_l)},${Math.max(
                  g_l,
                  w_l,
                )},${Math.max(b_l, w_l)})`,
              }}
            />
          </div>

          <ItemNumberField label="r_l" value={r_l} onChange={setDMX('r_l')} />
          <ItemNumberField label="g_l" value={g_l} onChange={setDMX('g_l')} />
          <ItemNumberField label="b_l" value={b_l} onChange={setDMX('b_l')} />
          <ItemNumberField label="w_l" value={w_l} onChange={setDMX('w_l')} />
          {onColorDelete && (
            <Button
              color="secondary"
              onClick={() => {
                confirm('Are you sure you want to delete this color?') &&
                  onColorDelete()
              }}
            >
              Delete
            </Button>
          )}
        </div>
      </div>
      <Dialog
        open={colorPickerActive}
        onClose={() => setColorPickerActive(false)}
        maxWidth={false}
      >
        <PhotoshopPicker
          color={hex}
          onChangeComplete={(color) =>
            setCustomColor({ ...customColor, hex: color.hex } as CustomColor)
          }
          onAccept={() => setColorPickerActive(false)}
          onCancel={() => setColorPickerActive(false)}
        />
      </Dialog>
    </>
  )
}

export default CustomColorRow

const useStyles = makeStyles((theme) => ({
  padding: {
    paddingBottom: 20,
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    padding: 10,
  },
  textField: {
    minWidth: '50px',
    maxWidth: '50px',
    margin: '0 10px',
  },
  largeTextField: {
    minWidth: '100px',
    maxWidth: '100%',
    margin: '0 10px',
  },
  divider: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem',
  },
  color: {
    minWidth: 40,
    minHeight: 40,
    backgroundColor: '#ffffff10',
    margin: 10,
    borderRadius: 8,
  },
  smallColor: {
    minWidth: 10,
    minHeight: 40,
    backgroundColor: '#ffffff10',
  },
  colorWrapper: {
    margin: '0 10px',
    backgroundColor: '#000',
    display: 'flex',
    borderRadius: 4,
    overflow: 'hidden',
  },
}))
