import { Language } from 'constants/languages'
import { nameFromThemeId } from './nameFromThemeId'

export const calculateFilteredViewTimeByTheme = (
  viewTimeByTheme: Record<string, number>,
) => {
  return Object.keys(viewTimeByTheme)
    .map((k) => ({
      name: k,
      watched: viewTimeByTheme[k] / 60 / 60,
    }))
    .filter((x) => x.watched && x.name !== 'undefined')
    .sort((a, b) => b.watched - a.watched)
}

export const getThemesHoursWatchedOverADayTranslated = (
  themes: readonly {
    readonly id: string
    readonly name: string
    readonly titleObject: Record<string, any>
  }[],
  themesHoursWatchedOverADay: Record<string, any>,
  language: Language,
): Record<string, number[]> =>
  Object.keys(themesHoursWatchedOverADay as Record<string, number[]>).reduce(
    (acc, themeId) => ({
      ...acc,
      [nameFromThemeId(themes, themeId, language)]:
        themesHoursWatchedOverADay[themeId],
    }),
    {},
  )

export const getThemesHoursWatchedOverAPeriodTranslated = (
  themes: readonly {
    readonly id: string
    readonly name: string
    readonly titleObject: Record<string, any>
  }[],
  themesHoursWatchedOverAPeriod: Record<string, any>,
  language: Language,
): Record<string, Record<string, number>> =>
  Object.keys(themesHoursWatchedOverAPeriod).reduce((acc, time) => {
    return {
      ...acc,
      [time]: Object.keys(
        themesHoursWatchedOverAPeriod[time] as Record<string, number>,
      ).reduce(
        (acc, themeId) => ({
          ...acc,
          [nameFromThemeId(themes, themeId, language)]:
            themesHoursWatchedOverAPeriod[time][themeId],
        }),
        {},
      ),
    }
  }, {})
