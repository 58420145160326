import { Grid, makeStyles } from '@material-ui/core'
import graphql from 'babel-plugin-relay/macro'
import moment from 'moment'
import { Suspense, useEffect, useMemo } from 'react'
import {
  fetchQuery,
  PreloadedQuery,
  usePreloadedQuery,
  useQueryLoader,
  UseQueryLoaderLoadQueryOptions,
  useRelayEnvironment,
} from 'react-relay'
import {
  HomeStatsQuery,
  HomeStatsQueryVariables,
} from './__generated__/HomeStatsQuery.graphql'
import { HomeThemesQuery } from './__generated__/HomeThemesQuery.graphql'
import { HomeUnitsQuery } from './__generated__/HomeUnitsQuery.graphql'

import { useTheme } from 'App'
import Loader from 'components/Loader'
import { DashboardStatistics } from 'components/statistics/DashboardStatistics'
import { FilterHeader } from 'components/statistics/FilterHeader'
import { i18nStrings } from 'constants/translations'
import {
  StatisticsFilterProvider,
  useStatisticsFilter,
} from 'context/StatisticsFilterContext'
import {
  calculateFilteredViewTimeByTheme,
  getThemesHoursWatchedOverADayTranslated,
  getThemesHoursWatchedOverAPeriodTranslated,
} from 'helpers/statisticsDatasetHelpers'
import { toPdf } from 'helpers/toPdf'
import { statisticsToXlsx } from 'helpers/xlsxExportHelper'
import './Home.css'
import UnitsByWeek from './components/UnitsByWeek'

const useStyles = makeStyles((theme: any) => ({
  item: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  header: {
    // width: 'calc(100vw - 270px)',
  },
  pdfTitle: {
    fontSize: '40px',
    fontWeight: 'bold',
    marginBottom: '10px',
    marginTop: '10px',
    textAlign: 'center',
  },
  pdfSubtitle: {
    fontSize: '30px',
    marginBottom: '10px',
    marginTop: '10px',
    textAlign: 'center',
  },
}))

const statsQuery = graphql`
  query HomeStatsQuery($input: StatsInput!) {
    getStats(input: $input) {
      totalUnits
      totalUnitsByWeek
      viewTimeByTheme
      themesHoursWatchedOverADay
      themesHoursWatchedOverAPeriod
    }
  }
`

const themesQuery = graphql`
  query HomeThemesQuery {
    themes {
      id
      name
      titleObject
    }
  }
`

const unitsQuery = graphql`
  query HomeUnitsQuery {
    units {
      id
      name
      placedAt
    }
  }
`

const HomeContainer = () => {
  const [statsQueryReference, loadStatsQuery] =
    useQueryLoader<HomeStatsQuery>(statsQuery)
  const [themesQueryReference, loadThemesQuery] =
    useQueryLoader<HomeThemesQuery>(themesQuery)
  const [unitsQueryReference, loadUnitsQuery] =
    useQueryLoader<HomeUnitsQuery>(unitsQuery)

  useEffect(() => {
    loadStatsQuery(
      {
        input: {
          themeIds: [],
          unitIds: [],
          customLightNames: [],
          language: 'en',
          startDate: moment().subtract(3, 'months').toDate(),
          endDate: new Date(),
        },
      },
      { fetchPolicy: 'network-only' },
    )
    loadThemesQuery({}, { fetchPolicy: 'network-only' })
    loadUnitsQuery({}, { fetchPolicy: 'network-only' })
  }, [])

  return (
    <Suspense fallback={<Loader />}>
      {statsQueryReference && themesQueryReference && unitsQueryReference && (
        <StatisticsFilterProvider>
          <Home
            statsQueryReference={statsQueryReference}
            themesQueryReference={themesQueryReference}
            unitsQueryReference={unitsQueryReference}
            loadStatsQuery={loadStatsQuery}
          />
        </StatisticsFilterProvider>
      )}
    </Suspense>
  )
}

interface HomeProps {
  statsQueryReference: PreloadedQuery<HomeStatsQuery>
  themesQueryReference: PreloadedQuery<HomeThemesQuery>
  unitsQueryReference: PreloadedQuery<HomeUnitsQuery>
  loadStatsQuery: (
    variables: HomeStatsQueryVariables,
    options?: UseQueryLoaderLoadQueryOptions | undefined,
  ) => void
}

const Home = ({
  statsQueryReference,
  themesQueryReference,
  unitsQueryReference,
  loadStatsQuery,
}: HomeProps) => {
  const {
    startDate,
    endDate,
    setCreatingXlsx,
    setCreatingPdf,
    language,
    selectedUnits,
    selectedThemes,
    selectedCustomLight,
  } = useStatisticsFilter()

  const strings = i18nStrings[language]

  const { themes } = usePreloadedQuery(themesQuery, themesQueryReference)
  const { units } = usePreloadedQuery(unitsQuery, unitsQueryReference)
  const {
    getStats: {
      totalUnits,
      totalUnitsByWeek,
      viewTimeByTheme,
      themesHoursWatchedOverADay,
      themesHoursWatchedOverAPeriod,
    },
  } = usePreloadedQuery(statsQuery, statsQueryReference)

  const classes = useStyles()
  const environment = useRelayEnvironment()

  const { handleThemeChange } = useTheme()

  useEffect(() => {
    const variables = {
      input: {
        unitIds: selectedUnits.map((x) => x.value),
        themeIds: selectedThemes.map((x) => x.value),
        customLightNames: selectedCustomLight.map((x) => x.value),
        startDate,
        endDate,
        language,
      },
    }
    // https://relay.dev/docs/next/guided-tour/refetching/refreshing-queries/
    // setIsRefreshing(true)
    fetchQuery(environment, statsQuery, variables).subscribe({
      complete: () => {
        // setIsRefreshing(false)
        loadStatsQuery(variables, { fetchPolicy: 'store-and-network' })
      },
      error: () => {
        // setIsRefreshing(false)
      },
    })
  }, [
    selectedUnits,
    selectedThemes,
    selectedCustomLight,
    startDate,
    endDate,
    language,
  ])

  const exportXlsx = async () => {
    setCreatingXlsx(true)
    await statisticsToXlsx({
      language,
      startDate,
      endDate,
      themesHoursWatchedOverAPeriod,
      selectedUnits,
      totalUnits,
      selectedThemes,
      themes,
      themesHoursWatchedOverADayTranslated,
      viewTimeByTheme,
    })
    setCreatingXlsx(false)
  }

  const exportPdf = async () => {
    setCreatingPdf(true)
    await toPdf({ elementId: 'pdf-export', language })
    setCreatingPdf(false)
  }

  const filteredViewTimeByTheme = useMemo(
    () => calculateFilteredViewTimeByTheme(viewTimeByTheme),
    [viewTimeByTheme],
  )

  const themesHoursWatchedOverADayTranslated =
    getThemesHoursWatchedOverADayTranslated(
      themes,
      themesHoursWatchedOverADay,
      language,
    )

  const themesHoursWatchedOverAPeriodTranslated =
    getThemesHoursWatchedOverAPeriodTranslated(
      themes,
      themesHoursWatchedOverAPeriod,
      language,
    )

  const dashboardStatisticsProps = {
    totalUnits,
    datasets: {
      themes,
      themesHoursWatchedOverADayTranslated,
      themesHoursWatchedOverAPeriodTranslated,
      viewTimeByTheme,
      filteredViewTimeByTheme,
      themesHoursWatchedOverADay,
      themesHoursWatchedOverAPeriod,
    },
  }

  const dateFormatter = new Intl.DateTimeFormat(language, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  })

  return (
    <div>
      {/* <div className={classes.headerWidth}> */}
      <FilterHeader
        headerClass={classes.header}
        unitSelectionData={units.map((unit) => ({
          value: unit.id,
          label: `${unit.name} - ${unit.placedAt}`,
        }))}
        themeSelectionData={themes.map((theme) => ({
          value: theme.id,
          label: theme.name,
        }))}
        addLightModeToggle={false}
        onExportPdf={exportPdf}
        onExportXlsx={exportXlsx}
      />
      {/* </div> */}

      <DashboardStatistics {...dashboardStatisticsProps} ispdfExport={false}>
        <Grid item xs={12}>
          <div className={classes.item}>
            <UnitsByWeek width={1330} unitsByWeek={totalUnitsByWeek} />
          </div>
        </Grid>
      </DashboardStatistics>
      {/* We set display to None to essentially hide the pdf-export until we generate pdf*/}
      <div
        id="pdf-export"
        style={{
          width: '1400px',
          display: 'none',
        }}
      >
        <div className={classes.item}>
          <p className={classes.pdfTitle}>{strings['Usage Statistics']}</p>
          <p className={classes.pdfSubtitle}>
            {dateFormatter.format(startDate!)} -{' '}
            {dateFormatter.format(endDate!)}
          </p>
        </div>

        <DashboardStatistics {...dashboardStatisticsProps} ispdfExport={true} />
      </div>
    </div>
  )
}

export default HomeContainer
