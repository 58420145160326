import React, { createContext, useContext } from 'react'
import './App.css'

import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { AuthProvider } from 'context'
import { QueryClient, QueryClientProvider } from 'react-query'
import Routes from './Routes'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 86_400_000,
    },
  },
})

const themeOverrides = {
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '16px',
      },
    },
  },
}

const createLightModeTheme = () =>
  createTheme({
    ...themeOverrides,
    palette: {
      type: 'light',
      primary: {
        main: '#FFFFFF',
        dark: '#F5F5F5',
        light: '#F0F0F0',
      },
      info: {
        main: '#8F4F4D',
      },
      background: {
        default: '#F5F5F5',
        paper: '#FFFFFF',
      },
      text: {
        primary: '#000000',
      },
    },
  })

const createDarkModeTheme = () =>
  createTheme({
    ...themeOverrides,
    palette: {
      type: 'dark',
      primary: {
        main: '#444444',
        dark: '#333',
        light: '#555555',
      },
      info: {
        main: '#8F4F4D',
      },
      background: {
        default: '#383434',
        paper: '#444444',
      },
      text: {
        primary: '#FFFFFF',
      },
    },
  })

const ThemeContext = createContext({
  theme: createDarkModeTheme(),
  handleThemeChange: () => {},
})

export const useTheme = () => useContext(ThemeContext)

const App = () => {
  const initialTheme = React.useMemo(
    () => JSON.parse(localStorage.getItem('isLightMode') || 'true'),
    [],
  )

  const [isLightMode, setIsLightMode] = React.useState<Boolean>(initialTheme)

  const theme = React.useMemo(
    () => (isLightMode ? createLightModeTheme() : createDarkModeTheme()),
    [isLightMode],
  )

  const handleThemeChange = () => {
    const newTheme = !isLightMode
    setIsLightMode(newTheme)
    localStorage.setItem('isLightMode', JSON.stringify(newTheme))
  }

  return (
    <ThemeContext.Provider value={{ theme, handleThemeChange }}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <QueryClientProvider client={queryClient}>
            <Routes />
          </QueryClientProvider>
        </AuthProvider>
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}

export default App
