import { FC, Suspense, useEffect, useMemo, useState } from 'react'

import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import graphql from 'babel-plugin-relay/macro'
import { DialogBox } from 'components'
import Loader from 'components/Loader'
import Fuse from 'fuse.js'
import { padStart } from 'lodash'
import MaterialTable from 'material-table'
import moment from 'moment'
import {
  PreloadedQuery,
  UseQueryLoaderLoadQueryOptions,
  usePreloadedQuery,
  useQueryLoader,
} from 'react-relay'
import {
  UnitsQuery,
  UnitsQueryVariables,
} from './__generated__/UnitsQuery.graphql'
import { UnitsThemeGroupsQuery } from './__generated__/UnitsThemeGroupsQuery.graphql'
import { Unit, useApi } from './api'
import { ListSelector } from './components'
import { Options } from './components/ListSelector'

const useStyles = makeStyles((theme) => ({
  page: {
    position: 'relative',
  },
  table: {
    width: '100%',
    position: 'relative',
    height: 400,
  },
  topBar: {
    margin: theme.spacing(1.5),
    position: 'absolute',
    zIndex: 200,
  },
}))

export enum ScreenType {
  Tv = 'tv',
  Projector = 'projector',
  Softlight = 'softlight',
  Moodlight = 'moodlight',
}

export enum LanguageCode {
  DA = 'da',
  EN = 'en',
  DE = 'de',
  FR = 'fr',
  CN = 'cn',
}

const themeGroupsQuery = graphql`
  query UnitsThemeGroupsQuery {
    themeGroups {
      id
      name
      themes
    }
  }
`

const unitsQuery = graphql`
  query UnitsQuery {
    units {
      id
      otp
      unitNumber
      comment
      locationPhotoUrl
      name
      languageCode
      mac
      placedAt
      screenType
      dmxDefaultGain
      softlightDefaultGain
      updatedAt
      updatedBy
      createdAt
      createdBy
      themes
      themeGroups
      socketId
      sshUrl
      lastLoggingDate
      unitType
      lastLoggingFirmware
      sshStatus
      networkMac
    }
    themes {
      id
      name
      thumbnailUrl
      isMediaComplete
      themeType
      workTitle
    }
  }
`

const sshUrlToCommand = (sshUrl: string) =>
  `ssh wavecare@${sshUrl.split(':')[1].slice(2)} -p ${sshUrl.split(':')[2]}`

type Props = {
  onlyMoodlight?: boolean
}

const UnitsContainer: FC<Props> = ({ onlyMoodlight }) => {
  const [themeGroupsQueryReference, loadThemeGroupsQuery] =
    useQueryLoader<UnitsThemeGroupsQuery>(themeGroupsQuery)
  const [unitsQueryReference, loadUnitsQuery] =
    useQueryLoader<UnitsQuery>(unitsQuery)

  useEffect(() => {
    loadThemeGroupsQuery({}, { fetchPolicy: 'network-only' })
    loadUnitsQuery({}, { fetchPolicy: 'network-only' })
  }, [])

  return (
    <Suspense fallback={<Loader />}>
      {themeGroupsQueryReference && unitsQueryReference && (
        <Units
          unitsQueryReference={unitsQueryReference}
          themeGroupsQueryReference={themeGroupsQueryReference}
          onRefresh={() => loadUnitsQuery({})}
          onlyMoodlight={onlyMoodlight}
        />
      )}
    </Suspense>
  )
}

interface UnitsProps {
  unitsQueryReference: PreloadedQuery<UnitsQuery>
  themeGroupsQueryReference: PreloadedQuery<UnitsThemeGroupsQuery>
  onRefresh: (
    v: UnitsQueryVariables,
    o?: UseQueryLoaderLoadQueryOptions,
  ) => void
  onlyMoodlight?: boolean
}

const Units = ({
  unitsQueryReference,
  onRefresh,
  themeGroupsQueryReference,
  onlyMoodlight,
}: UnitsProps) => {
  const { units, themes } = usePreloadedQuery(unitsQuery, unitsQueryReference)
  const { themeGroups } = usePreloadedQuery(
    themeGroupsQuery,
    themeGroupsQueryReference,
  )
  const { page, topBar } = useStyles()
  const { createUnit, updateUnit, deleteUnit, generateOtp } = useApi()

  const formatter = new Intl.DateTimeFormat('da', {
    minute: 'numeric',
    hour: 'numeric',
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  })

  const [unit, setUnit] = useState<Unit>()

  const [allThemes, setAllThemes] = useState<Options>([])
  const [searchWord, setSearchWord] = useState('')
  const [subscriptionPackageSearchWord, setSubscriptionPackageSearchWord] =
    useState('')

  useEffect(() => {
    setAllThemes(
      themes
        .filter((t) => t.isMediaComplete)
        // .filter((t) => !t.themeType || t.themeType === unit?.unitType)
        .map((theme) => ({
          value: theme.id,
          label: `${theme.themeType ?? 'No type'}: ${theme.name} ${
            theme.workTitle && `(${theme.workTitle})`
          }`,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    )
  }, [themes, unit])

  const createNewUnit = () => {
    setUnit({
      id: '',
      unitNumber: -1,
      name: '',
      comment: '',
      placedAt: '',
      languageCode: LanguageCode.EN,
      screenType: onlyMoodlight ? ScreenType.Moodlight : ScreenType.Tv,
      dmxDefaultGain: 100,
      softlightDefaultGain: 100,
      mac: '',
      networkMac: '',
      themes: [],
      themeGroups: [],
      unitType: null,
    })
  }

  const getActions = () => {
    const actions = []

    if (unit?.id) {
      actions.push({
        onClick: () => {
          if (
            !unit ||
            prompt('To delete this unit, write DELETE UNIT?', '') !==
              'DELETE UNIT'
          ) {
            alert('Wrong input, should be DELETE UNIT')
            return
          }
          deleteUnit({ id: unit.id })
          setUnit(undefined)
        },
        title: 'Delete UNIT',
      })
    }

    actions.push({
      onClick: () => setUnit(undefined),
      title: 'Cancel',
    })

    if (!unit?.id) {
      actions.push({
        onClick: () => {
          if (!unit) return
          const { id, unitNumber, ...unitWithoutId } = unit
          createUnit({ ...unitWithoutId, comment: unitWithoutId.comment ?? '' })
          setUnit(undefined)
        },
        title: 'Save',
      })
    }
    if (unit?.id) {
      actions.push({
        onClick: () => {
          if (!unit) return
          const {
            id,
            languageCode,
            name,
            comment,
            placedAt,
            screenType,
            dmxDefaultGain,
            softlightDefaultGain,
            themes,
            themeGroups,
            unitType,
          } = unit
          updateUnit({
            id,
            languageCode,
            name,
            comment,
            placedAt,
            screenType,
            dmxDefaultGain,
            softlightDefaultGain,
            themes,
            themeGroups,
            unitType,
          })
          setUnit(undefined)
        },
        title: 'Update',
      })
    }

    return actions
  }

  const setField = (
    field: keyof Unit,
    value: string | string[] | number | null,
  ) =>
    setUnit(
      (prevUnit: Unit | undefined) =>
        prevUnit && { ...prevUnit, [field]: value },
    )

  const dateDiffToday = (date: string) => {
    const today = new Date()
    const dateToCompare = new Date(date)
    const diff = today.getTime() - dateToCompare.getTime()
    const diffInDays = Math.floor(diff / (1000 * 3600 * 24))
    return diffInDays
  }

  const mapScreenTypeToLabel = (screenType: string) => {
    return (
      {
        tv: 'clear',
      }[screenType] || screenType
    )
  }

  const startTunnel = () => {
    updateUnit({
      id: unit?.id ?? '',
      sshStatus: 'start',
    })
  }

  const stopTunnel = () => {
    updateUnit({
      id: unit?.id ?? '',
      sshStatus: 'stop',
    })
  }

  const createUnitTitle = (unit: Unit) =>
    `WB${padStart(unit.unitNumber + '', 3, '0')}`

  // Search
  const options = {
    threshold: 0.5,
    keys: ['label'],
  }

  const fuse = new Fuse(allThemes, options)
  const searchResult = fuse.search(searchWord.trim())
  const filteredThemes = searchWord
    ? searchResult.map((t) => t.item)
    : allThemes
  const withSelectedThemes = [
    ...filteredThemes.filter((t) => !unit?.themes?.includes(t.value)),
    ...allThemes.filter((t) => unit?.themes?.includes(t.value)),
  ]

  const allSubPackOptions = themeGroups.map((t) => ({
    value: t.id,
    label: t.name,
  }))

  const fuseSubPack = new Fuse(allSubPackOptions, options)
  const searchResultSubPack = fuseSubPack.search(
    subscriptionPackageSearchWord.trim(),
  )
  const filteredThemesSubPack = subscriptionPackageSearchWord
    ? searchResultSubPack.map((t) => t.item)
    : allSubPackOptions
  const withSelectedThemesSubPack = [
    ...filteredThemesSubPack.filter(
      (t) => !unit?.themeGroups?.includes(t.value),
    ),
    ...allSubPackOptions.filter((t) => unit?.themeGroups?.includes(t.value)),
  ]

  return (
    <div className={page}>
      <div className={topBar}>
        <Button onClick={createNewUnit} variant="outlined">
          {onlyMoodlight ? 'Create new Mood Light' : 'Create new Wavecare Box'}
        </Button>
      </div>
      {units &&
        useMemo(
          () => (
            <MaterialTable
              title=""
              columns={[
                {
                  field: 'unitNumber',
                  title: 'Unit ID',
                  defaultSort: 'desc',
                  render: createUnitTitle,
                },
                {
                  field: 'socketId',
                  title: 'Online',
                  render: ({ lastLoggingDate }) =>
                    moment(lastLoggingDate as string).isAfter(
                      moment().subtract(5, 'minutes'),
                    )
                      ? 'Yes'
                      : 'No',
                },
                { field: 'name', title: 'Name' },
                {
                  field: 'unitType',
                  title: 'Theme Type',
                  render: ({ unitType, screenType }) =>
                    screenType === 'moodlight' ? 'No themes' : unitType,
                  hidden: onlyMoodlight,
                },
                {
                  field: 'themes',
                  title: '# of themes',
                  hidden: onlyMoodlight,
                  render: ({
                    themes: unitThemes,
                    themeGroups: unitThemeGroups,
                  }) => {
                    const unitInGroups = themeGroups.filter((x) =>
                      unitThemeGroups?.includes(x.id),
                    )
                    return new Set([
                      ...unitInGroups.map((x) => x.themes).flat(),
                      ...(unitThemes as Array<string>),
                    ]).size
                  },
                },
                { field: 'placedAt', title: 'Placed at' },
                { field: 'languageCode', title: 'Language' },
                {
                  field: 'screenType',
                  title: 'Screen/room type',
                  render: ({ screenType }) => mapScreenTypeToLabel(screenType),
                },
                {
                  field: 'lastLoggingDate',
                  title: 'Last Logging Date',
                  render: ({ lastLoggingDate }) =>
                    lastLoggingDate
                      ? formatter.format(new Date(lastLoggingDate as string))
                      : 'Unknown',
                },
                { field: 'lastLoggingFirmware', title: 'Firmware' },
                {
                  field: 'updatedAt',
                  title: 'Updated at',
                  render: ({ updatedAt }) =>
                    formatter.format(new Date(updatedAt as string)),
                },
                {
                  field: 'createdAt',
                  title: 'Created at',
                  render: ({ createdAt }) =>
                    formatter.format(new Date(createdAt as string)),
                },
              ]}
              options={{
                pageSize: 10,
                sorting: true,
                exportButton: true,
                headerStyle: { backgroundColor: 'transparent' },
              }}
              style={{ backgroundColor: 'transparent' }}
              onRowClick={(_, unit) => {
                if (!unit) return
                const { createdAt, updatedAt, ...unitWithoutTime } = unit
                setUnit({
                  ...unitWithoutTime,
                  languageCode: unit.languageCode as any,
                  screenType: unit.screenType as any,
                  themes: unit.themes as any,
                  themeGroups: unit.themeGroups as any,
                  unitType: unit.unitType as any,
                  sshStatus: unit.sshStatus as any,
                })
              }}
              data={
                units
                  .map((o) => ({ ...o }))
                  .filter((o) =>
                    onlyMoodlight
                      ? o.screenType === 'moodlight'
                      : o.screenType !== 'moodlight',
                  ) as any
              }
            />
          ),
          [units, onlyMoodlight],
        )}
      <DialogBox
        open={!!unit}
        title={unit?.id ? createUnitTitle(unit) : 'Create new unit'}
        fields={[
          {
            type: 'textField',
            label: 'Name',
            value: unit?.name ?? '',
            onChange: (name: string) => setField('name', name),
          },
          {
            type: 'textField',
            label: 'Comment',
            value: unit?.comment ?? '',
            onChange: (comment: string) => setField('comment', comment),
          },
          {
            type: 'textField',
            label: 'Placed at',
            value: unit?.placedAt ?? '',
            onChange: (placedAt: string) => setField('placedAt', placedAt),
          },
          {
            type: 'selectorField',
            label: 'Language code',
            value: unit?.languageCode ?? '',
            options: ['en', 'da', 'se', 'de', 'cn', 'fr'],
            onChange: (languageCode: string) =>
              setField('languageCode', languageCode),
          },
          {
            type: 'selectorField',
            label: 'Screen/room type',
            value: mapScreenTypeToLabel(unit?.screenType ?? 'tv'),
            options: ['clear', 'projector', 'softlight', 'moodlight'],
            onChange: (screenType: string) =>
              setField(
                'screenType',
                screenType === 'clear' ? 'tv' : screenType,
              ),
          },
          {
            type: 'selectorField',
            label: 'Theme type',
            value: unit?.unitType ?? '',
            options: ['SDR', 'SR', 'EDC', 'softlight'],
            onChange: (unitType: string) => setField('unitType', unitType),
            isHidden: unit?.screenType === 'moodlight',
          },
          {
            type: 'numberField',
            label: 'DMX default gain',
            value: unit?.dmxDefaultGain ?? 100,
            onChange: (dmxDefaultGain: string) =>
              setField('dmxDefaultGain', dmxDefaultGain),
          },
          {
            type: 'numberField',
            label: 'Softlight default gain',
            value: unit?.softlightDefaultGain ?? 100,
            onChange: (softlightDefaultGain: string) =>
              setField('softlightDefaultGain', softlightDefaultGain),
            isHidden: unit?.screenType !== 'softlight',
          },
          {
            type: 'textField',
            label: 'Search for themes',
            value: searchWord,
            onChange: (searchWord: string) => setSearchWord(searchWord),
          },
          unit?.screenType !== 'moodlight' && (
            <>
              <p>Themes</p>
              <ListSelector
                allOptions={withSelectedThemes}
                selected={unit?.themes ?? []}
                onChange={(e) => setField('themes', e)}
              />
            </>
          ),
          {
            type: 'textField',
            label: 'Search for subscription package',
            value: subscriptionPackageSearchWord,
            onChange: (searchWord: string) =>
              setSubscriptionPackageSearchWord(searchWord),
          },
          unit?.screenType !== 'moodlight' && (
            <>
              <p>Subscription packages</p>
              <ListSelector
                allOptions={withSelectedThemesSubPack}
                selected={unit?.themeGroups ?? []}
                onChange={(e) => setField('themeGroups', e)}
              />
            </>
          ),
          <p style={{ marginTop: 10, marginBottom: 10 }} key="mac">
            Hardware MAC: {unit?.mac}
          </p>,
          <p style={{ marginTop: 10, marginBottom: 10 }} key="networkMac">
            Network MAC: {unit?.networkMac}
          </p>,
          unit?.id ? (
            <Button
              key="generateOtpButton"
              onClick={async () => {
                const r = await generateOtp({ id: unit.id })
                setField('otp', r.generateOtp.unit.otp)
              }}
              variant="outlined"
              color="secondary"
            >
              Generate OTP
            </Button>
          ) : (
            <b>Save unit to generate OTP</b>
          ),
          unit?.id ? (
            <p key="otp" style={{ marginTop: 10 }}>
              OTP: {unit?.otp}
            </p>
          ) : null,
          <h3>SSH: {unit?.sshStatus}</h3>,
          <p>
            Check tunnels here:{' '}
            <a
              href="https://dashboard.ngrok.com/tunnels/agents"
              target="_blank"
              style={{ color: '#0094bf' }}
            >
              https://dashboard.ngrok.com/tunnels/agents
            </a>
          </p>,
          <Button
            onClick={() => startTunnel()}
            variant="outlined"
            color="secondary"
          >
            Start SSH tunnel
          </Button>,
          <Button
            onClick={() => stopTunnel()}
            variant="outlined"
            color="secondary"
          >
            Stop SSH tunnel
          </Button>,
        ]}
        actions={getActions()}
      />
    </div>
  )
}

export default UnitsContainer
