import { useTheme } from '@material-ui/core'
import TextField from '@mui/material/TextField'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from 'moment'

interface ItemFilterProps {
  label: string
  date: Date | null
  setDate: (date: Date | null) => void
}

const ItemFilter = ({ label, date, setDate }: ItemFilterProps) => {
  const muiTheme = useTheme()
  return (
    <DatePicker
      label={label}
      value={date}
      onChange={setDate}
      rifmFormatter={(date) => moment(date).format('DD/MM/YYYY')}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            input: { color: muiTheme.palette.text.primary },
            label: { color: muiTheme.palette.text.primary },
          }}
        />
      )}
    />
  )
}

export default ItemFilter
