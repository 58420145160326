import { Button, makeStyles } from '@material-ui/core'
import { Chip } from '@types'
import { ChipSelector, DialogBox, UploadField } from 'components'
import { useAuth } from 'hooks'
import { nanoid } from 'nanoid'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { getSignedUrlForFiles } from './api'
import { ItemCard } from './components'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 8,
  },
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  uploadButton: {
    margin: 4,
  },
}))

const storageFilter: Chip[] = [
  { id: 'sound', name: 'Sound', isSelected: false, disabled: false },
  { id: 'softlight', name: 'Softlight', isSelected: false, disabled: false },
  { id: 'thumbnail', name: 'Thumbnail', isSelected: false, disabled: false },
  { id: 'encrypted', name: 'Encrypted', isSelected: false, disabled: false },
  { id: 'original', name: 'Original', isSelected: false, disabled: false },
]

export type StorageItem = {
  Key: string
  LastModified: string
  ETag: string
  Size: number
  StorageClass: string
  Owner: {
    ID: string
  }
  type: string
  thumbnailSignedUrl: string
}

interface StorageProps {
  onItemClicked?: (item: StorageItem) => void
  preselect?: string[]
  preFetchedData?: {
    isLoading: boolean
    error: unknown
    data: StorageItem[] | undefined
  } | null
}

const Storage = ({
  onItemClicked,
  preselect,
  preFetchedData,
}: StorageProps) => {
  const [chips, setChips] = useState<Chip[]>(storageFilter)
  const [open, setOpen] = useState<boolean>(false)

  const classes = useStyles()
  const { user } = useAuth()
  const { isLoading, error, data } = useQuery<StorageItem[]>(
    'repoData',
    async () =>
      fetch(process.env.REACT_APP_API_ENDPOINT + '/storage/files', {
        headers: new Headers({
          Authorization: `Bearer ${await user?.getIdToken()}`,
        }),
      }).then((res) => res.json()),
    {
      refetchOnMount: 'always',
      retry: 3,
    },
  )

  useEffect(() => {
    setChips(
      chips.map((c) => ({
        ...c,
        isSelected: preselect ? preselect.includes(c.id) : false,
      })),
    )
  }, [])

  const chipFilter = (x: StorageItem) => {
    const selectedChips = chips.filter((c) => c.isSelected)
    if (selectedChips.length === 0) return true
    return selectedChips.map((c) => c.id).includes(x.Key?.split('/')[0])
  }

  const getSignedUrl = async (
    filename: string,
    jwt: string,
  ): Promise<string | null> => {
    return await getSignedUrlForFiles(nanoid(4) + '_' + filename, jwt)
  }

  const sort = (a: StorageItem, b: StorageItem) =>
    new Date(b.LastModified).getTime() - new Date(a.LastModified).getTime()

  if (isLoading) return <div>Loading...</div>
  if (error) return <div>Error</div>

  return (
    <div className={classes.container}>
      <div className={classes.uploadButton}>
        <Button onClick={() => setOpen(true)} variant="outlined">
          Upload file
        </Button>
      </div>
      <ChipSelector chips={chips} setChips={setChips} />
      <div className={classes.cardContainer}>
        {data &&
          data
            .filter(chipFilter)
            .sort(sort)
            .map((item: StorageItem, i: number) => {
              return (
                <ItemCard
                  item={item}
                  key={i}
                  onClick={() => onItemClicked && onItemClicked(item)}
                />
              )
            })}
      </div>

      <DialogBox
        open={open}
        title="Upload video or image"
        fields={[
          <UploadField
            key="uploadField"
            getSignedUrl={getSignedUrl}
            title="Drag and drop a new video or image here"
          />,
        ]}
        actions={[
          {
            onClick: () => setOpen(false),
            title: 'Close',
          },
        ]}
      />
    </div>
  )
}

export default Storage
