import { Grid, makeStyles } from '@material-ui/core'
import graphql from 'babel-plugin-relay/macro'
import Loader from 'components/Loader'
import MaterialTable from 'material-table'
import { FC, Suspense, useEffect, useMemo } from 'react'
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay'
import { MobileStatsQuery } from './__generated__/MobileStatsQuery.graphql'

const useStyles = makeStyles((theme: any) => ({
  page: {
    padding: theme.spacing(2),
  },
  item: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    textAlign: 'center',
  },
}))

const mobileStatsQuery = graphql`
  query MobileStatsQuery {
    mobileAppUsers {
      totalAmount
    }
    accumulatedMobileAppStats {
      stats
    }
    accumulatedMobileAppQRLocations {
      statsAccumulated
    }
  }
`

const MobileStatsContainer = () => {
  const [mobileStatsQueryReference, loadStatsQuery] =
    useQueryLoader<MobileStatsQuery>(mobileStatsQuery)

  useEffect(() => {
    loadStatsQuery({}, { fetchPolicy: 'network-only' })
  }, [])

  return (
    <Suspense fallback={<Loader />}>
      {mobileStatsQueryReference && (
        <MobileStats mobileStatsQueryReference={mobileStatsQueryReference} />
      )}
    </Suspense>
  )
}

interface Props {
  mobileStatsQueryReference: PreloadedQuery<MobileStatsQuery>
}

const MobileStats: FC<Props> = (p) => {
  const {
    mobileAppUsers,
    accumulatedMobileAppStats,
    accumulatedMobileAppQRLocations,
  } = usePreloadedQuery(mobileStatsQuery, p.mobileStatsQueryReference)
  const classes = useStyles()

  const filteredViewTimeByTheme = useMemo(
    () =>
      Object.keys(accumulatedMobileAppStats.stats)
        .map((k) => ({
          name: k,
          watched: accumulatedMobileAppStats.stats[k] / 60 / 60,
        }))
        .filter((x) => x.watched && x.name !== 'undefined')
        .sort((a, b) => b.watched - a.watched),
    [],
  )

  const filteredQRLocations = useMemo(
    () =>
      Object.keys(accumulatedMobileAppQRLocations.statsAccumulated)
        .map((k) => ({
          name: k,
          users: accumulatedMobileAppQRLocations.statsAccumulated[k],
        }))
        .filter((x) => x.users && x.name !== 'undefined')
        .sort((a, b) => b.users - a.users),
    [],
  )

  const totalHoursWatched = useMemo(
    () => filteredViewTimeByTheme.reduce((acc, curr) => acc + curr.watched, 0),
    [filteredViewTimeByTheme],
  )

  return (
    <div className={classes.page}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} className="section-not-to-print">
          <div className={classes.item}>
            <p>Total amount of app users</p>
            <h1>{mobileAppUsers.totalAmount}</h1>
          </div>
        </Grid>
        <Grid item xs={12} md={6} className="section-not-to-print">
          <div className={classes.item}>
            <p>Total amount of hours watched</p>
            <h1>{Math.round(totalHoursWatched)}</h1>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <MaterialTable
            title="Users per Location"
            columns={[
              {
                field: 'name',
                title: 'Location',
                headerStyle: { fontWeight: 'bold' },
                render: (rowData) => rowData.name,
              },
              {
                field: 'users',
                title: 'Amount of Users',
                headerStyle: { fontWeight: 'bold' },
                render: (rowData) => rowData.users,
              },
            ]}
            data={filteredQRLocations}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MaterialTable
            title="Hours Watched per Theme"
            columns={[
              {
                field: 'name',
                title: 'Theme',
                headerStyle: { fontWeight: 'bold' },
                render: (rowData) => rowData.name,
              },
              {
                field: 'watched',
                title: 'Hours Watched',
                headerStyle: { fontWeight: 'bold' },
                render: (rowData) => {
                  const hours = Math.floor(rowData.watched)
                  const minutes = Math.round((rowData.watched - hours) * 60)
                  return `${hours}h ${minutes}m`
                },
              },
            ]}
            data={filteredViewTimeByTheme}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default MobileStatsContainer
