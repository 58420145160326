import { Suspense, useCallback, useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { Chip, DMX, LocalizationObject } from '@types'
import graphql from 'babel-plugin-relay/macro'
import { DialogBox } from 'components'
import Loader from 'components/Loader'
import { startCase } from 'lodash'
import MaterialTable from 'material-table'
import { CustomColorRow } from 'pages/CustomColors/components'
import { ValueLabel } from 'pages/Home/components/SelectItem'
import { MediaObject } from 'pages/Storage/components/FileCardV2'
import FileCardV2Simple from 'pages/Storage/components/FileCardV2Simple'
import StorageKanbanBoard from 'pages/Storage/StorageKanbanBoard'
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay'
import {
  ThemesQuery,
  ThemesQueryResponse,
} from './__generated__/ThemesQuery.graphql'
import { useApi } from './api'
import { ColorThumbnail } from './components'

const useStyles = makeStyles((theme) => ({
  page: {
    position: 'relative',
  },
  table: {
    width: '100%',
    position: 'relative',
    height: 400,
  },
  topBar: {
    margin: theme.spacing(1.5),
    position: 'absolute',
    zIndex: 200,
    display: 'flex',
    alignItems: 'center',
    height: 40,
  },
  row: {
    display: 'flex',
  },
  themeTypeHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'translateY(100%)',
  },
}))

const themesQuery = graphql`
  query ThemesQuery {
    themes {
      id
      name
      titleObject
      descriptionObject
      rawVideoUrl
      thumbnailUrl
      signedThumbnailUrl
      videoUrl
      softlightUrl
      soundUrl
      dmx
      updatedAt
      createdAt
      themeType
      dmxDefaultGain
      isMediaComplete
      categories
      defaultSoftlightGain
      durationInSeconds
      workTitle
    }
    categories
  }
`

const ThemesContainer = () => {
  const [queryReference, loadQuery] = useQueryLoader<ThemesQuery>(themesQuery)

  useEffect(() => {
    loadQuery({}, { fetchPolicy: 'network-only' })
  }, [])

  const refetch = useCallback(() => {
    loadQuery({}, { fetchPolicy: 'network-only' })
  }, [])

  return (
    <Suspense fallback={<Loader/>}>
      {queryReference && (
        <Themes refetch={refetch} queryReference={queryReference} />
      )}
    </Suspense>
  )
}

interface ThemesProps {
  queryReference: PreloadedQuery<ThemesQuery>
  refetch: () => void
}

const themeFilter: Chip[] = [
  { id: 'sdr', name: 'SDR', isSelected: false, disabled: false },
  { id: 'sr', name: 'SR', isSelected: false, disabled: false },
  { id: 'softlight', name: 'Softlight', isSelected: false, disabled: false },
  { id: 'mobile', name: 'Mobile', isSelected: false, disabled: false },
]

const Themes = ({ queryReference, refetch }: ThemesProps) => {
  const { themes, categories } = usePreloadedQuery(themesQuery, queryReference)
  const [open, setOpen] = useState<boolean>(false)
  const {
    createTheme,
    updateTheme,
    canDeleteTheme,
    deleteTheme,
    deleteThemeAndDependencies,
  } = useApi()

  const [id, setId] = useState<string>('')
  const [originalKey, setOriginalKey] = useState<string>('')
  const [softlightKey, setSoftlightKey] = useState<string>('')
  const [soundKey, setSoundKey] = useState<string>('')
  const [encryptedKey, setEncryptedKey] = useState<string>('')
  const [thumbnailKey, setThumbnailKey] = useState<string>('')
  const [signedThumbnailUrl, setSignedThumbnailUrl] = useState<string | null>(
    '',
  )
  const [titleObject, setTitleObject] =
    useState<Record<keyof LocalizationObject, string | null | undefined>>()
  const [descriptionObject, setDescriptionObject] =
    useState<Record<keyof LocalizationObject, string | null | undefined>>()
  const [dmx, setDmx] = useState<Record<keyof DMX, number | null | undefined>>()
  const [themeType, setThemeType] = useState<string | null | undefined>()
  const [themeCategories, setThemeCategories] = useState<ValueLabel[]>([])
  const [dmxDefaultGain, setDmxDefaultGain] = useState<number>(100)
  const [durationInSeconds, setDurationInSeconds] = useState<number>(0)
  const [workTitle, setWorkTitle] = useState<string>('')
  const [defaultSoftlightGain, setDefaultSoftlightGain] = useState<number>(100)

  const [isUpdate, setIsUpdate] = useState(false)
  const [themeFileModalIsOpen, setThemeFileModalIsOpen] = useState(false)
  const [chips, setChips] = useState<Chip[]>(themeFilter)

  const { page, topBar, row, themeTypeHeader } = useStyles()

  const formatter = new Intl.DateTimeFormat('da', {
    minute: 'numeric',
    hour: 'numeric',
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  })

  type Theme = ThemesQueryResponse['themes'][0]

  const createNewTheme = () => {
    setIsUpdate(false)

    setOriginalKey('')
    setSoftlightKey('')
    setSoundKey('')
    setEncryptedKey('')
    setThumbnailKey('')
    setThemeType('')
    setThemeCategories([])
    setDmxDefaultGain(100)
    setDurationInSeconds(0)
    setDefaultSoftlightGain(100)

    setTitleObject({} as Record<keyof LocalizationObject, string>)
    setDescriptionObject({} as Record<keyof LocalizationObject, string>)
    setDmx({} as Record<keyof DMX, number>)
    setOpen(true)
  }

  const createThemePayload = () => ({
    name: titleObject?.EN,
    titleObject,
    descriptionObject,
    rawVideoUrl: originalKey,
    videoUrl: encryptedKey,
    thumbnailUrl: thumbnailKey,
    softlightUrl: softlightKey,
    soundUrl: soundKey,
    dmx,
    themeType: themeType || null,
    dmxDefaultGain,
    defaultSoftlightGain,
    durationInSeconds,
    workTitle,
    categories: themeCategories.map((c) => c.value) || [],
  })

  const onSave = async () => {
    await createTheme(createThemePayload())
    refetch()
    setOpen(false)
  }

  const onUpdate = async () => {
    await updateTheme({ id, ...createThemePayload() })
    refetch()
    setOpen(false)
  }

  const onDelete = async () => {
    const res = await canDeleteTheme({ id })
    if (res.canDeleteTheme.status === 'in use') {
      const { unitCount, themeGroupCount } = res.canDeleteTheme
      if (
        confirm(
          `This theme is used by ${unitCount} units and ${themeGroupCount} subscription packges. Do you want to delete the theme in these together with the theme itself?`,
        )
      ) {
        await deleteThemeAndDependencies({ id })
        setOpen(false)
      }
    } else {
      if (
        !confirm(
          'This theme is not in use. Are you sure you want to delete this theme?',
        )
      )
        return
      await deleteTheme({ id })
      setOpen(false)
    }
  }

  const onRowClick = (theme: Theme | null) => {
    if (!theme) return
    setIsUpdate(true)

    setId(theme.id)
    setOriginalKey(theme.rawVideoUrl)
    setSoftlightKey(theme.softlightUrl)
    setSoundKey(theme.soundUrl)
    setEncryptedKey(theme.videoUrl)
    setThumbnailKey(theme.thumbnailUrl)
    setSignedThumbnailUrl(theme.signedThumbnailUrl)
    setTitleObject(theme.titleObject)
    setDescriptionObject(theme.descriptionObject)
    setDmx(theme.dmx)
    setThemeType(theme.themeType)
    setThemeCategories(
      theme.categories.concat().map((c) => ({ value: c, label: c })),
    )
    setDmxDefaultGain(theme.dmxDefaultGain)
    setDurationInSeconds(theme.durationInSeconds)
    setWorkTitle(theme.workTitle)
    setDefaultSoftlightGain(theme.defaultSoftlightGain)

    setOpen(true)
  }

  const getActions = () => {
    const actions = []

    if (isUpdate) {
      actions.push({
        onClick: onDelete,
        title: 'Delete',
      })
    }

    actions.push({
      onClick: () => setOpen(false),
      title: 'Cancel',
    })

    if (!isUpdate) {
      actions.push({
        onClick: onSave,
        title: 'Save',
      })
    } else {
      actions.push({
        onClick: onUpdate,
        title: 'Update',
      })
    }

    return actions
  }

  const onSelectMediaObject = (mediaObject: MediaObject) => {
    mediaObject.original?.Key && setOriginalKey(mediaObject.original?.Key)
    mediaObject.softlight?.Key && setSoftlightKey(mediaObject.softlight?.Key)
    mediaObject.sound?.Key && setSoundKey(mediaObject.sound?.Key)
    mediaObject.encrypted?.Key && setEncryptedKey(mediaObject.encrypted?.Key)
    mediaObject.thumbnail?.Key && setThumbnailKey(mediaObject.thumbnail?.Key)
    mediaObject.signedThumbnail &&
      setSignedThumbnailUrl(mediaObject.signedThumbnail)
    setThemeFileModalIsOpen(false)
  }

  const DMX_BLACK = {
    r: 0,
    g: 0,
    b: 0,
    w: 0,
    r_l: 0,
    g_l: 0,
    b_l: 0,
    w_l: 0,
  }

  const THEME_TYPES = ['inbox', 'SR', 'SDR', 'EDC', 'softlight', 'mobile']

  return (
    <div className={page}>
      <div className={topBar}>
        <Button onClick={createNewTheme} variant="outlined">
          Create new theme
        </Button>
      </div>

      <div className={row}>
        {themes &&
          THEME_TYPES.map((themeType) => (
            <div>
              <div className={themeTypeHeader}>
                <h1>{startCase(themeType)}</h1>
              </div>
              <MaterialTable
                title=""
                columns={[
                  {
                    field: 'thumbnailUrl',
                    title: 'Thumbnail',
                    render: ({ signedThumbnailUrl }) =>
                      signedThumbnailUrl ? (
                        <div
                          style={{
                            borderRadius: 8,
                            overflow: 'hidden',
                            width: 110,
                            height: 70,
                          }}
                        >
                          <img
                            src={signedThumbnailUrl}
                            style={{ width: 110, height: 70 }}
                          />
                        </div>
                      ) : null,
                  },
                  {
                    field: 'name',
                    title: 'Name',
                    render: ({ name, workTitle }) => (
                      <p style={{ minWidth: '170px' }}>
                        {name + (workTitle && ` (${workTitle})`)}
                      </p>
                    ),
                  },
                  {
                    field: 'dmx',
                    title: 'DMX',
                    render: ({ dmx, dmxDefaultGain }) => (
                      <ColorThumbnail
                        dmx={dmx as DMX}
                        dmxDefaultGain={dmxDefaultGain}
                        small
                      />
                    ),
                  },
                  {
                    defaultSort: 'desc',
                    field: 'updatedAt',
                    title: 'Updated',
                    render: ({ updatedAt }) =>
                      formatter.format(new Date(updatedAt as string)),
                  },
                ]}
                options={{
                  pageSize: 30,
                  pageSizeOptions: [30],
                  sorting: true,
                  // exportButton: true,
                  search: false,
                  headerStyle: { backgroundColor: 'transparent' },
                }}
                style={{ backgroundColor: 'transparent' }}
                onRowClick={(_, theme) => {
                  onRowClick({ ...theme } as Theme)
                }}
                data={themes
                  .map((o) => ({ ...o }))
                  .map((o) => ({
                    ...o,
                    themeType: o.themeType ?? 'inbox',
                  }))
                  .filter((o) => o.themeType === themeType)}
              />
            </div>
          ))}
      </div>
      <DialogBox
        open={open}
        title="Theme"
        size={'90vw'}
        fields={[
          {
            type: 'selectorField',
            label: 'Theme type',
            value: themeType || null,
            options: ['SDR', 'SR', 'EDC', 'softlight', 'mobile'],
            onChange: setThemeType,
          },
          themeType === 'mobile' && {
            type: 'selectItems',
            label: 'Theme categories',
            value: themeCategories ?? '',
            options: categories.map((c) => ({ value: c, label: c })),
            selectedItemsOption: themeCategories,
            setSelectedItemsOption: setThemeCategories,
          },
          {
            type: 'textField',
            label: 'Work title',
            value: workTitle ?? '',
            onChange: setWorkTitle,
          },
          {
            type: 'multiTextField',
            title: 'Theme Title',
            labels: ['EN', 'DA', 'SE', 'DE', 'CN', 'FR'],
            values: [
              titleObject?.EN,
              titleObject?.DA,
              titleObject?.SE,
              titleObject?.DE,
              titleObject?.CN,
              titleObject?.FR,
            ],
            onChange: (v: string, k: keyof LocalizationObject) =>
              setTitleObject((t) => ({ ...t, [k]: v } as LocalizationObject)),
          },
          {
            type: 'multiTextField',
            title: 'Theme Description',
            labels: ['EN', 'DA', 'SE', 'DE', 'CN', 'FR'],
            values: [
              descriptionObject?.EN,
              descriptionObject?.DA,
              descriptionObject?.SE,
              descriptionObject?.DE,
              descriptionObject?.CN,
              descriptionObject?.FR,
            ],
            onChange: (v: string, k: keyof LocalizationObject) =>
              setDescriptionObject(
                (t) => ({ ...t, [k]: v } as LocalizationObject),
              ),
            isMultiline: true,
          },
          {
            type: 'numberField',
            label: 'Theme duration in seconds',
            value: durationInSeconds ?? 0,
            onChange: (v: string) =>
              setDurationInSeconds(Math.min(36000, Math.max(0, parseInt(v)))),
          },
          <div
            key="files"
            style={{
              maxWidth: '470px',
              marginBottom: '20px',
            }}
          >
            <p style={{ marginBottom: '10px' }}>Theme file</p>
            <FileCardV2Simple
              key="selectThemeFile"
              signedImageUrl={signedThumbnailUrl || ''}
              filename={originalKey.split('/').pop() || ''}
              onSelectNew={() => setThemeFileModalIsOpen(true)}
            />
          </div>,
          themeType !== 'mobile' && (
            <div key="chooseDMX">
              <CustomColorRow
                showColorPicker={false}
                showColorName={false}
                customColor={{
                  id: 'original',
                  name: 'original',
                  hex: '',
                  gain: dmxDefaultGain ?? 100,
                  dmx: dmx
                    ? Object.keys(DMX_BLACK).reduce(
                        (acc, key) => ({
                          ...acc,
                          [key]: dmx?.[key as keyof DMX] || 0,
                        }),
                        {} as DMX,
                      )
                    : DMX_BLACK,
                }}
                setCustomColor={(customColor) => {
                  setDmx(
                    Object.keys(customColor.dmx).reduce(
                      (acc, k) => ({
                        ...acc,
                        [k]: Math.min(
                          255,
                          Math.max(0, customColor.dmx[k as keyof DMX]),
                        ),
                      }),
                      {} as DMX,
                    ),
                  )
                  setDmxDefaultGain(
                    Math.min(100, Math.max(0, customColor.gain)),
                  )
                }}
              />
            </div>
          ),
          // themeType === 'softlight' && {
          //   type: 'numberField',
          //   label: 'Softlight default gain',
          //   value: defaultSoftlightGain ?? 100,
          //   onChange: (v: string) =>
          //     setDefaultSoftlightGain(Math.min(100, Math.max(0, parseInt(v)))),
          // },
        ]}
        actions={getActions()}
      />
      <DialogBox
        open={themeFileModalIsOpen}
        title="Select theme file"
        size={'90vw'}
        fields={[
          <StorageKanbanBoard onSelectMediaObject={onSelectMediaObject} />,
        ]}
        actions={[
          {
            onClick: () => setThemeFileModalIsOpen(false),
            title: 'Cancel',
          },
        ]}
      />
    </div>
  )
}

export default ThemesContainer
