import DualListBox from 'react-dual-listbox'

import './ListSelector.css'

export type Options = {
  value: string
  label: string
}[]

interface ListSelectorProps {
  allOptions: Options
  selected: string[]
  onChange: (v: string[]) => void
}

const ListSelector = ({
  allOptions,
  selected,
  onChange,
}: ListSelectorProps) => {
  return (
    <div
      className={
        localStorage.getItem('isLightMode') === 'true' ? 'light' : 'dark'
      }
      style={{
        marginTop: 10,
      }}
    >
      <DualListBox
        preserveSelectOrder
        showOrderButtons
        options={allOptions}
        selected={selected}
        onChange={onChange}
      />
    </div>
  )
}

export default ListSelector
