import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Snackbar } from '@mui/material'
import { CustomColor, DMX } from '@types'
import graphql from 'babel-plugin-relay/macro'
import Loader from 'components/Loader'
import { isEqual } from 'lodash'
import { Suspense, useEffect, useState } from 'react'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay'
import { v4 as uuidv4 } from 'uuid'
import { CustomColorsQuery } from './__generated__/CustomColorsQuery.graphql'
import { useApi } from './api'
import { CustomColorRow } from './components'

const customColorsQuery = graphql`
  query CustomColorsQuery {
    defaultCustomColorRef {
      id
      customColor
    }
  }
`

const CustomColorsContainer = () => {
  const [queryReference, loadQuery] =
    useQueryLoader<CustomColorsQuery>(customColorsQuery)

  useEffect(() => {
    loadQuery({}, { fetchPolicy: 'network-only' })
  }, [])

  return (
    <Suspense fallback={<Loader />}>
      {queryReference && <CustomColors queryReference={queryReference} />}
    </Suspense>
  )
}

interface CustomColorsProps {
  queryReference: PreloadedQuery<CustomColorsQuery>
}

const CustomColors = ({ queryReference }: CustomColorsProps) => {
  const { defaultCustomColorRef } = usePreloadedQuery(
    customColorsQuery,
    queryReference,
  )
  const [customColors, setCustomColors] = useState<CustomColor[]>(
    defaultCustomColorRef.customColor as CustomColor[],
  )
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const classes = useStyles()
  const { updateCustomColorRef } = useApi()

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return // dropped outside the list
    const list = Array.from(customColors)
    const [removed] = list.splice(result.source.index, 1)
    list.splice(result.destination.index, 0, removed)
    setCustomColors(list)
  }

  const addNewColor = () => {
    setCustomColors((c) => [
      ...c,
      {
        id: uuidv4(),
        name: `New color #${c.length + 1}`,
        hex: '#000000',
        dmx: {
          r: 0,
          g: 0,
          b: 0,
          w: 0,
          r_l: 0,
          g_l: 0,
          b_l: 0,
          w_l: 0,
        } as DMX,
        gain: 100,
      },
    ])
  }

  const onColorDelete = (id: string) => {
    setCustomColors((c) => c.filter((color) => color.id !== id))
  }

  const setCustomColor = (id: string, customColor: CustomColor) => {
    setCustomColors((c) => c.map((c) => (c.id === id ? { ...customColor } : c)))
  }

  const onSavedClicked = async () => {
    const res = await updateCustomColorRef({
      id: defaultCustomColorRef.id,
      customColor: customColors,
    })
    if (res.updateCustomColorRef.customColorRef) {
      setSnackbarOpen(true)
    }
  }

  return (
    <div className={classes.root}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {customColors.map((customColor, index) => (
                <Draggable
                  draggableId={customColor.id}
                  key={customColor.id}
                  index={index}
                >
                  {(provided) => (
                    <div
                      key={customColor.id}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <CustomColorRow
                        customColor={customColor as unknown as CustomColor}
                        setCustomColor={(c: CustomColor) =>
                          setCustomColor(customColor.id, c)
                        }
                        onColorDelete={() => onColorDelete(customColor.id)}
                        key={index}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        className={classes.button}
        variant="contained"
        onClick={addNewColor}
      >
        Add new color
      </Button>
      <Button
        className={classes.button}
        variant="contained"
        disabled={isEqual(
          JSON.stringify(customColors),
          JSON.stringify(defaultCustomColorRef.customColor),
        )}
        onClick={() => {
          confirm(
            'Are you sure you want to replace the custom colors on all units?',
          ) && onSavedClicked()
        }}
      >
        Save as new default custom colors
      </Button>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message="Saved custom colors"
      />
    </div>
  )
}

export default CustomColorsContainer

const useStyles = makeStyles(() => ({
  root: {
    padding: 20,
  },
  button: {
    margin: 20,
  },
}))
