import { Language } from 'constants/languages'
import moment from 'moment'
import { ValueLabel } from 'pages/Home/components/SelectItem'
import React, { createContext, useContext, useState } from 'react'

interface FilterContextType {
  startDate: Date | null
  setStartDate: (date: Date | null) => void

  endDate: Date | null
  setEndDate: (date: Date | null) => void

  language: Language
  setLanguage: (language: Language) => void

  selectedUnits: ValueLabel[]
  setSelectedUnits: (units: ValueLabel[]) => void

  selectedThemes: ValueLabel[]
  setSelectedThemes: (themes: ValueLabel[]) => void

  selectedCustomLight: ValueLabel[]
  setSelectedCustomLight: (customLights: ValueLabel[]) => void

  creatingPdf: boolean
  setCreatingPdf: (creating: boolean) => void

  creatingXlsx: boolean
  setCreatingXlsx: (creating: boolean) => void
}

const FilterContext = createContext<FilterContextType | null>(null)

export const useStatisticsFilter = () => {
  const context = useContext(FilterContext)
  if (!context) {
    throw new Error(
      'useStatisticsFilter must be used within a StatisticsFilterProvider',
    )
  }
  return context
}

export const StatisticsFilterProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  // Manages the states used in /Home and /Stats

  const [startDate, setStartDate] = useState<Date | null>(
    moment().subtract(3, 'months').startOf('day').toDate(),
  )
  const [endDate, setEndDate] = useState<Date | null>(
    moment().endOf('day').toDate(),
  )

  const [language, setLanguage] = useState<Language>('en')

  const [selectedUnits, setSelectedUnits] = useState<ValueLabel[]>([])
  const [selectedThemes, setSelectedThemes] = useState<ValueLabel[]>([])
  const [selectedCustomLight, setselectedCustomLight] = useState<ValueLabel[]>(
    [],
  )

  const [creatingPdf, setCreatingPdf] = useState<boolean>(false)
  const [creatingXlsx, setCreatingXlsx] = useState<boolean>(false)

  return (
    <FilterContext.Provider
      value={{
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        language,
        setLanguage,
        selectedUnits,
        setSelectedUnits,
        selectedThemes,
        setSelectedThemes,
        selectedCustomLight,
        setSelectedCustomLight: setselectedCustomLight,
        creatingPdf,
        setCreatingPdf,
        creatingXlsx,
        setCreatingXlsx,
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}
