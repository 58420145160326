import { Language } from 'constants/languages'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

const getCanvas = (id: string): Promise<HTMLCanvasElement> =>
  new Promise((resolve) => {
    html2canvas(document.getElementById(id) as HTMLElement, {
      scale: 1.5,
      // onClone allows us to "unhide" the pdf-export
      onclone: (document) => {
        const element = document.getElementById(id)
        if (element && element.style.display === 'none') {
          element.style.display = 'block'
        }
      },
    }).then((canvas) => {
      resolve(canvas)
    })
  })

type Props = {
  elementId: string
  language: Language
}

export const toPdf = async ({ elementId, language }: Props) => {
  const canvas = await getCanvas(elementId)
  const pdf = new jsPDF({ unit: 'px', format: [canvas.width, canvas.height] })
  pdf.addImage(
    canvas.toDataURL('image/jpeg'),
    'JPEG',
    0,
    0,
    canvas.width,
    canvas.height,
  )

  const formatter = new Intl.DateTimeFormat(language, {
    minute: 'numeric',
    hour: 'numeric',
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hourCycle: 'h23',
  })

  pdf.save(
    `Wavecare Report ${formatter.format(new Date())}.pdf`
      .replace(/,/g, '')
      .split(' ')
      .join('_')
      .toLocaleLowerCase(),
  )
}
