import { Typography, useTheme } from '@material-ui/core'
import HelpIcon from '@mui/icons-material/Help'
import { Language } from 'constants/languages'
import { i18nStrings } from 'constants/translations'
import MaterialTable from 'material-table'

export const ThemeOverADayTable = ({
  title,
  data,
  language,
  isPdfExport = false,
}: {
  title: string
  data: Record<string, number[]>
  language: Language
  isPdfExport?: boolean
}) => {
  if (!data) return null

  const strings = i18nStrings[language]

  const columns = Object.entries(data)
    .map(([name, arr]) => ({
      name,
      sum: arr.reduce((sum, num) => sum + num, 0),
      watchedArray: arr,
    }))
    .sort((a, b) => b.sum - a.sum)
    .map(({ name, watchedArray }) => ({ name, watchedArray }))

  const muiTheme = useTheme()

  const colorscale = [
    `${muiTheme.palette.text.primary}08`,
    'rgba(253, 239, 179)',
    'rgb(253, 237, 176)',
    'rgb(249, 198, 139)',
    'rgb(244, 159, 109)',
    'rgb(234, 120, 88)',
    'rgb(218, 83, 82)',
    'rgb(191, 54, 91)',
    'rgb(158, 35, 98)',
    'rgb(120, 26, 97)',
    'rgb(83, 22, 84)',
    'rgb(47, 15, 61)',
  ]

  const maxValue = Math.max(...columns.map((d) => Math.max(...d.watchedArray)))
  const amountOfDataPoints = columns[0]?.watchedArray?.length

  return (
    <>
      <MaterialTable
        title={<Typography>{title}</Typography>}
        columns={[
          { field: 'name', title: strings['Theme Name'] },
          {
            field: 'watched',
            title: (
              <div
                style={{
                  height: 26,
                  width: '100%',
                  display: 'flex',
                  transform: `translateY(20px)`,
                  marginTop: 6,
                  marginBottom: 12,
                  alignItems: 'flex-end',
                }}
              >
                {[...new Array(amountOfDataPoints)].map((_, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        width: '100%',
                        height: '100%',
                        paddingRight: 4,
                      }}
                    >
                      {i % 4 === 0 && (
                        <div
                          style={{
                            color: muiTheme.palette.text.primary,
                            fontSize: 10,
                          }}
                        >
                          {i < 10 ? `0${i}:00` : `${i}:00`}
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            ),
            render: (rowData) => (
              <div
                style={{
                  width: '100%',
                  height: 25,
                  borderRadius: '6px',
                  display: 'flex',
                }}
              >
                {rowData.watchedArray.map((watched, i) => {
                  const gradientIndex = Math.min(
                    Math.ceil(
                      (Math.round(watched * 10) / (maxValue * 10)) *
                        (colorscale.length - 1),
                    ),
                    colorscale.length - 1,
                  )

                  return (
                    <div
                      key={i}
                      style={{
                        width: '100%',
                        height: '100%',
                        paddingRight: 2,
                        paddingLeft: 2,
                        position: 'relative',
                      }}
                    >
                      <div
                        key={i}
                        style={{
                          borderRadius: '4px',
                          width: '100%',
                          height: '100%',
                          backgroundColor: colorscale[gradientIndex],
                          marginBottom: 4,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {watched.toFixed(1) !== '0.0' && (
                          <div
                            style={{
                              color: gradientIndex > 5 ? 'white' : '#00000099',
                              fontWeight: 'bold',
                              fontSize: 10,
                            }}
                          >
                            {watched > 10
                              ? Math.round(watched)
                              : watched.toFixed(1)}
                          </div>
                        )}
                      </div>
                      {i % 4 === 0 && (
                        <div
                          style={{
                            borderLeft: `1px solid `,
                            transform: 'scaleY(1.5) translateX(-0.5px)',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            bottom: 0,
                          }}
                        />
                      )}
                    </div>
                  )
                })}
              </div>
            ),
          },
        ]}
        options={{
          search: !isPdfExport,
          paging: !isPdfExport,
          pageSize: isPdfExport ? columns.length : 10,
          pageSizeOptions: [10, 20, 30, 50, 100, 150, 200],
          sorting: false,
          exportButton: !isPdfExport,
          padding: 'dense',
        }}
        actions={[
          {
            icon: () => <HelpIcon />,
            tooltip: strings.GraphToolTips['ThemeOverADayTable'],
            isFreeAction: true,
            onClick: () => {},
            hidden: isPdfExport,
          },
        ]}
        data={columns}
      />
    </>
  )
}
