import { InputLabel, makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Storage } from 'pages'
import { ItemCard } from 'pages/Storage/components'
import { StorageItem } from 'pages/Storage/Storage'
import { useEffect, useState } from 'react'

const useStyles = makeStyles(() => ({
  container: {
    width: '90vw',
    height: '80vh',
    margin: -10, // compensate for storage padding
  },
  cardContainer: {
    margin: 10,
    marginTop: 20,
  },
}))

interface SelectFileProps {
  label: string
  value: string
  onChange: (val: string) => void
  preselect?: string[]
  data?: StorageItem[]
}

const SelectFile = ({
  label,
  value,
  onChange,
  preselect,
  data,
}: SelectFileProps) => {
  const [selectModalOpen, setSelectModalOpen] = useState(false)
  const [currentItem, setCurrentItem] = useState<StorageItem | null>()
  const classes = useStyles()

  useEffect(() => {
    setCurrentItem(data ? data.find((item) => item.Key === value) : null)
  }, [value])

  const onItemClicked = (item: StorageItem) => {
    onChange(item.Key)
    setSelectModalOpen(false)
  }

  return (
    <div className="default-component">
      {value ? (
        <div className={classes.cardContainer}>
          <InputLabel onClick={() => setSelectModalOpen(true)}>
            {label}
          </InputLabel>
          <div style={{ height: 10 }} />
          <ItemCard
            item={currentItem}
            onClick={() => setSelectModalOpen(true)}
          />
        </div>
      ) : (
        <div className={classes.cardContainer}>
          <InputLabel>{label}</InputLabel>
          <div style={{ height: 10 }} />
          <Button onClick={() => setSelectModalOpen(true)} variant="outlined">
            Select file
          </Button>
        </div>
      )}
      <Dialog disableBackdropClick open={selectModalOpen} maxWidth={false}>
        <DialogTitle>Select file ({label})</DialogTitle>
        <DialogContent>
          <div className={classes.container}>
            <Storage
              onItemClicked={onItemClicked}
              preselect={preselect}
              preFetchedData={{ isLoading: false, error: null, data }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <div style={{ width: '100%' }} />
          <Button onClick={() => setSelectModalOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default SelectFile
