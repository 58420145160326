import { Language } from 'constants/languages'
import { i18nStrings } from 'constants/translations'
import moment from 'moment'
import { ValueLabel } from 'pages/Home/components/SelectItem'
import * as XLSX from 'xlsx'
import { nameFromThemeId } from './nameFromThemeId'

type ExportAsXlsxProps = {
  data: {
    title: string
    header?: string[]
    rows?: {
      name: string
      values: number[]
    }[]
  }[]
}

export const exportAsXlsx = (p: ExportAsXlsxProps) => {
  const output = [] as any[]

  p.data.forEach((d) => {
    output.push([d.title])
    d.header && output.push([undefined, ...d.header])
    d.rows &&
      d.rows.forEach((r) => {
        output.push([r.name, ...r.values])
      })
    output.push([])
  })

  const wb = XLSX.utils.book_new()
  const ws = XLSX.utils.aoa_to_sheet(output)

  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

  const base64 = XLSX.write(wb, {
    type: 'base64',
  })

  return base64
}

export const statisticsToXlsx = async ({
  language,
  startDate,
  endDate,
  themesHoursWatchedOverAPeriod,
  selectedUnits,
  totalUnits,
  selectedThemes,
  themes,
  themesHoursWatchedOverADayTranslated,
  viewTimeByTheme,
}: {
  language: Language
  startDate: Date | null
  endDate: Date | null
  themesHoursWatchedOverAPeriod: Record<string, Record<string, number>>
  selectedUnits: ValueLabel[]
  totalUnits: number | null
  selectedThemes: ValueLabel[]
  themes: readonly {
    readonly id: string
    readonly name: string
    readonly titleObject: Record<string, Record<string, string>>
  }[]
  themesHoursWatchedOverADayTranslated: Record<string, number[]>
  viewTimeByTheme: Record<string, any>
}) => {
  const strings = i18nStrings[language]

  const days = moment(endDate).diff(moment(startDate), 'days')
  const keys = new Set(
    Object.keys(themesHoursWatchedOverAPeriod)
      .map((k) => themesHoursWatchedOverAPeriod[k])
      .map((v) => Object.keys(v))
      .flat(),
  )

  const formatter = new Intl.DateTimeFormat(language, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  })

  const dayHours = [...new Array(24)].map(
    (_, i) => i.toString().padStart(2, '0') + '.00',
  )

  const totalHoursWatched =
    Object.keys(viewTimeByTheme).reduce(
      (acc, k) => viewTimeByTheme[k] + acc,
      0,
    ) / 3600

  const dataOverPeriod = days
    ? [...Array(days).keys()].map((day) => {
        const date = moment(startDate).add(day, 'days').toDate()
        const dayKey = Math.floor(date.getTime() / 1000 / 60 / 60 / 24) // Same as log.resolver.ts
        const obj = {
          name: formatter.format(date),
        } as Record<string, any>
        keys.forEach((themeName) => {
          obj[themeName] =
            themesHoursWatchedOverAPeriod[dayKey]?.[themeName] || 0
        })
        return obj
      })
    : []
  const data = [
    {
      title: `${strings['Start date']}: ${
        startDate
          ? formatter.format(startDate)
          : dataOverPeriod[0]
          ? dataOverPeriod[0].name
          : strings['First entry']
      }`,
    },
    {
      title: `${strings['End date']}: ${
        endDate ? formatter.format(endDate) : formatter.format(new Date())
      }`,
    },
    {
      title: `${strings['Included units']}: ${
        selectedUnits.length || totalUnits
      }`,
    },
    {
      title: `${strings['Included themes']}: ${
        selectedThemes.length || Object.keys(viewTimeByTheme).length
      }`,
    },
    {
      title: strings['Total hours watched (over selected period)'],
      header: [strings['Hours']],
      rows: [{ name: 'Total', values: [totalHoursWatched.toFixed(1)] }],
    },
    {
      title: strings['View time per theme (accumulated over selected period)'],
      header: [strings['Hours']],
      rows:
        Object.keys(viewTimeByTheme as Record<string, number[]>).map(
          (themeId) => ({
            name: nameFromThemeId(themes, themeId, language),
            values: [viewTimeByTheme[themeId] / 60 / 60],
          }),
        ) || [],
    },
    {
      title: strings['Hourly Accumulation of Viewing Time Across Themes'],
      header: dayHours,
      rows:
        Object.keys(
          themesHoursWatchedOverADayTranslated as Record<string, number[]>,
        ).map((themeTitle) => ({
          name: themeTitle,
          values: themesHoursWatchedOverADayTranslated[themeTitle],
        })) || [],
    },
    {
      title: strings['Cumulative Hours Watched by Time of Day'],
      header: dayHours,
      rows: [
        {
          name: strings['Total'],
          values: Object.keys(
            themesHoursWatchedOverADayTranslated as Record<string, number[]>,
          ).reduce((acc, themeTitle) => {
            const values = themesHoursWatchedOverADayTranslated[themeTitle]
            return acc.map((x, i) => x + values[i])
          }, new Array(24).fill(0)),
        },
      ],
    },
    {
      title: strings[
        'Amount of Hours Watched per Theme per {interval}'
      ].replace('{interval}', strings['Day'].toLowerCase()),
      header: dataOverPeriod.map((x) => x.name),
      rows:
        (dataOverPeriod[0] &&
          Object.keys(dataOverPeriod[0])
            .filter((x) => x !== 'name')
            .map((themeId) => ({
              name: nameFromThemeId(themes, themeId, language),
              values: dataOverPeriod.map((x) => x[themeId]),
            }))) ||
        [],
    },
    {
      title: strings['Total Amount of Hours Watched per Day'],
      header: dataOverPeriod.map((x) => x.name),
      rows: [
        {
          name: 'Total',
          values:
            (dataOverPeriod[0] &&
              Object.keys(dataOverPeriod[0])
                .filter((x) => x !== 'name')
                .reduce((acc, themeTitle) => {
                  const values = dataOverPeriod.map((x) => x[themeTitle])
                  return acc.map((x, i) => x + values[i])
                }, new Array(dataOverPeriod.length).fill(0))) ||
            [],
        },
      ],
    },
  ]
  const base64xlsx = exportAsXlsx({ data })
  const xlsxString = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64xlsx}`
  const link = document.createElement('a')
  link.href = xlsxString
  link.download = 'wavecare_box_stats_export.xlsx'
  link.click()
}
