import { Button, CircularProgress, makeStyles } from '@material-ui/core'

interface ExportButtonProps {
  onExportClicked: () => void
  onExportXlsxClicked: () => void
  creatingPdf: boolean
  creatingXlsx: boolean
}

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'nowrap',
  },
  button: {
    whiteSpace: 'nowrap',
  },
}))

const ExportButton = ({
  onExportClicked,
  onExportXlsxClicked,
  creatingPdf,
  creatingXlsx,
}: ExportButtonProps) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <Button
        onClick={() => onExportClicked()}
        disabled={creatingPdf}
        className={classes.button}
      >
        {creatingPdf ? (
          <>
            <CircularProgress size={20} />
            <>Exporting PDF</>
          </>
        ) : (
          <>Export PDF</>
        )}
      </Button>
      <Button
        onClick={() => onExportXlsxClicked()}
        disabled={creatingXlsx}
        className={classes.button}
      >
        {creatingXlsx ? (
          <>
            <CircularProgress size={20} />
            <>Exporting Excel</>
          </>
        ) : (
          <>Export Excel</>
        )}
      </Button>
    </div>
  )
}

export default ExportButton
