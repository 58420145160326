import { CircularProgress, useTheme } from '@material-ui/core'

const Loader = () => {
  const muiTheme = useTheme()
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <CircularProgress
        style={{
          color: `${muiTheme.palette.text.primary}80`,
          width: '60px',
          height: '60px',
        }}
      />
    </div>
  )
}

export default Loader
