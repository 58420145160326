import { makeStyles, useTheme } from '@material-ui/core'
import { TooltipProps } from 'recharts'

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    paddingTop: '10px',
  },
  title: {
    marginBottom: '24px',
  },
  customRechartsTooltip: {
    textAlign: 'left',
  },
}))

interface CustomTooltipProps extends TooltipProps<number, string> {
  x_label?: string
  y_label?: string
}
export const CustomRechartsTooltip = ({
  x_label,
  y_label,
  active,
  payload,
  label,
}: CustomTooltipProps) => {
  // Custom tooltip for the hover effect when your cursor is on a chart
  const muiTheme = useTheme()
  const classes = useStyles()
  if (active) {
    const y_value = payload?.[0]?.value
    return (
      <div
        className={classes.customRechartsTooltip}
        style={{
          borderRadius: '8px',
          backgroundColor: muiTheme.palette.background.default,
          padding: '10px',
        }}
      >
        {x_label && <p>{`${x_label}: ${label}`}</p>}
        {y_label && <p>{`${y_label}: ${Math.round(y_value ?? 0)}`}</p>}
      </div>
    )
  }

  return null
}
