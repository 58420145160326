import { Language } from 'constants/languages'

export const nameFromThemeId = (
  themes: readonly {
    readonly id: string
    readonly name: string
    readonly titleObject: Record<string, any>
  }[],
  themeId: string,
  language: Language,
) => {
  const titleObj =
    themes.find((theme) => theme.id === themeId)?.titleObject || {}
  return titleObj?.[language.toUpperCase()] ?? titleObj?.['EN'] ?? 'Unknown'
}
