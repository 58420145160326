import { Button, makeStyles } from '@material-ui/core'
import moment from 'moment'

interface SetDateIntervalButtonProps {
  setStartDate: (start: Date | null) => void
  setEndDate: (end: Date | null) => void
}

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'nowrap',
  },
  button: {
    whiteSpace: 'nowrap',
  },
}))

const SetDateIntervalButton = ({
  setStartDate,
  setEndDate,
}: SetDateIntervalButtonProps) => {
  const classes = useStyles()

  const setStartDateToToday = (start: Date) => {
    setStartDate(start)
    setEndDate(new Date())
  }

  return (
    <div className={classes.wrapper}>
      {(
        [
          'day',
          'week',
          'month',
          'year',
        ] as moment.unitOfTime.DurationConstructor[]
      ).map((unit) => (
        <Button
          onClick={() =>
            setStartDateToToday(
              moment().subtract(1, unit).startOf('day').toDate(),
            )
          }
          className={classes.button}
        >
          1{unit[0]}
        </Button>
      ))}
      <Button
        className={classes.button}
        onClick={() => {
          setStartDate(null)
          setEndDate(null)
        }}
      >
        Reset date
      </Button>
    </div>
  )
}

export default SetDateIntervalButton
