import { makeStyles, useTheme } from '@material-ui/core'
import { CustomRechartsTooltip } from 'components/CustomRechartsTooltip'
import { Language } from 'constants/languages'
import { i18nStrings } from 'constants/translations'
import { GraphTitle } from 'pages/CustomColors/GraphTitle'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    paddingTop: '10px',
  },
  title: {
    marginBottom: '24px',
  },
}))

interface ThemeWatchOverADayProps {
  width?: number
  themesHoursWatchedOverADay: Record<string, number[]>
  title: string
  language: Language
  combine?: boolean
  hideLegend?: boolean
  isPdfExport?: boolean
}

const ThemeWatchOverADay = ({
  width,
  themesHoursWatchedOverADay,
  title,
  language,
  isPdfExport = false,
  combine = false,
  hideLegend = false,
}: ThemeWatchOverADayProps) => {
  const muiTheme = useTheme()
  const strings = i18nStrings[language]

  const classes = useStyles()

  const data = [...Array(24).keys()].map((hour) => {
    const themeNames = Object.keys(themesHoursWatchedOverADay)
    const obj = {
      name: `${hour}:00`,
    } as Record<string, any>
    themeNames.forEach((themeName) => {
      if (combine) {
        obj.combined =
          (obj.combined ?? 0) + themesHoursWatchedOverADay[themeName][hour]
      } else {
        obj[themeName] = themesHoursWatchedOverADay[themeName][hour]
      }
    })
    return obj
  })

  const tooltipText = strings.GraphToolTips['ThemeWatchOverADay'].replace(
    '{hours}',
    Math.round(
      data.find((item) => item.name === '14:00')?.combined ?? 0,
    ).toString(),
  )

  return (
    <div className={classes.wrapper}>
      <GraphTitle
        title={title}
        tooltip={isPdfExport ? undefined : tooltipText}
      />

      {themesHoursWatchedOverADay ? (
        <ResponsiveContainer width={isPdfExport ? width : '100%'} height={300}>
          <LineChart
            margin={{
              top: 10,
              bottom: 10,
              right: 30,
              left: 0,
            }}
            data={data}
          >
            <CartesianGrid stroke={muiTheme.palette.primary.light} />
            <XAxis interval={1} dataKey="name" />
            <YAxis interval={1} />
            <RechartsTooltip
              content={
                <CustomRechartsTooltip
                  x_label={strings['Time of Day']}
                  y_label={strings['Hours Watched']}
                />
              }
            />

            {Object.keys(data[0])
              .filter((n) => n !== 'name')
              .sort((a, b) => a.localeCompare(b))
              .map((themeName, i) => (
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  dataKey={themeName}
                  stroke={RANDOM_COLORS[i % RANDOM_COLORS.length]}
                  name={themeName}
                  dot={false}
                />
              ))}
            {!hideLegend && <Legend />}
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <p>No data</p>
      )}
    </div>
  )
}

export default ThemeWatchOverADay

const RANDOM_COLORS = [
  '#FF6633',
  '#FFB399',
  '#FF33FF',
  '#FFFF99',
  '#00B3E6',
  '#E6B333',
  '#3366E6',
  '#999966',
  '#99FF99',
  '#B34D4D',
  '#80B300',
  '#809900',
  '#E6B3B3',
  '#6680B3',
  '#66991A',
  '#FF99E6',
  '#CCFF1A',
  '#FF1A66',
  '#E6331A',
  '#33FFCC',
  '#66994D',
  '#B366CC',
  '#4D8000',
  '#B33300',
  '#CC80CC',
  '#66664D',
  '#991AFF',
  '#E666FF',
  '#4DB3FF',
  '#1AB399',
  '#E666B3',
  '#33991A',
  '#CC9999',
  '#B3B31A',
  '#00E680',
  '#4D8066',
  '#809980',
  '#E6FF80',
  '#1AFF33',
  '#999933',
  '#FF3380',
  '#CCCC00',
  '#66E64D',
  '#4D80CC',
  '#9900B3',
  '#E64D66',
  '#4DB380',
  '#FF4D4D',
  '#99E6E6',
]
