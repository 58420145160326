export const i18nStrings = {
  en: {
    'Total amount of units': 'Total amount of units',
    Units: 'Units',
    'Showing for {unitCount} units and {themeCount} themes':
      'Showing for {unitCount} Units and {themeCount} Themes',
    'Number of themes watched': 'Number of themes watched',
    'Themes watched': 'Themes watched',
    'Hours Watched': 'Hours Watched',
    Minutes: 'Minutes',
    Hours: 'Hours',
    Days: 'Days',
    Weeks: 'Weeks',
    Months: 'Months',
    Day: 'Day',
    Week: 'Week',
    Month: 'Month',
    Date: 'Date',
    'Time of Day': 'Time of Day',
    'Time Spent Watching Each Theme': 'Time Spent Watching Each Theme',
    'Time Spent Watching': 'Time Spent Watching',
    watched: 'watched',
    'Hours watched over a day': 'Hours watched over a day',
    'Hours watched over a period': 'Hours watched over a period',
    'Start date': 'Start date',
    'First entry': 'First entry',
    'End date': 'End date',
    'Included units': 'Included units',
    'Included themes': 'Included themes',
    'Total hours watched (over selected period)':
      'Total hours watched (over selected period)',
    'View time per theme (accumulated over selected period)':
      'View time per theme (accumulated over selected period)',
    'Hourly Accumulation of Viewing Time Across Themes':
      'Hourly Accumulation of Viewing Time Across Themes',
    'Cumulative Hours Watched by Time of Day':
      'Cumulative Hours Watched by Time of Day',
    'Amount of Hours Watched per Theme per {interval}':
      'Amount of Hours Watched per Theme per {interval}',
    'Total Amount of Hours Watched per Day':
      'Total Amount of Hours Watched per Day',
    'Total Time Spent Watching': 'Total Time Spent Watching',
    Total: 'Total',
    'Theme Name': 'Theme Name',
    'Usage Statistics': 'Usage Statistics',
    GraphToolTips: {
      ThemeOverADayTable:
        'The table shows the amount of hours each theme has been watched at specific times of day. For example, the first column shows the amount of hours each theme has been watched between 00:00 and 01:00 in the selected period. Note that the table aggregates the hours of all selected units.',
      ThemeWatchOverADay:
        'For each hour of the day, the graph shows the total amount of hours watched across all selected themes and units. For example, at 14:00 there has been seen a total of {hours} hours across all selected units in the selected time-period. Essentially, the graph depicts how much the units are used throughout the day.',
      ThemeWatchOverAPeriodTable:
        'The table shows the amount of hours each theme has been watched per {interval} for all selected units. Essentially, it shows how much themes are watched over time.',
      ThemeWatchOverAPeriod:
        "The graph shows the aggregated amount of hours watched on a daily basis for the selected units and themes. Because it's across all selected units, the hours watched can exceed 24 hours if more than one unit is selected.",
    },
  },
  fr: {
    'Total amount of units': 'Nombre total d’unités',
    Units: 'Unités',
    'Showing for {unitCount} units and {themeCount} themes':
      'Affichage pour {unitCount} unités et {themeCount} thèmes',
    'Number of themes watched': 'Nombre de thèmes visionnés',
    'Themes watched': 'Thèmes visionnés',
    'Hours Watched': 'Nombre d’heures visionnées',
    Minutes: 'Minutes',
    Hours: 'Heures',
    Days: 'Jours',
    Weeks: 'Semaines',
    Months: 'Mois',
    Day: 'Jour',
    Week: 'Semaine',
    Month: 'Mois',
    Date: 'Date',
    'Time of Day': 'Heure de la journée',
    'Time Spent Watching Each Theme': 'Temps de visionnage par thème',
    'Time Spent Watching': 'Temps de visionnage',
    watched: 'visionnage',
    'Hours watched over a day': 'Nombre d’heures visionnées par jour',
    'Hours watched over a period': 'Nombre d’heures visionnées sur une période',
    'Start date': 'Date de début',
    'First entry': 'Première entrée',
    'End date': 'Date de fin',
    'Included units': 'Unités incluses',
    'Included themes': 'Thèmes inclus',
    'Total hours watched (over selected period)':
      'Nombre total d’heures visionnées (sur la période sélectionnée)',
    'View time per theme (accumulated over selected period)':
      'Temps de visionnage par thème (accumulé sur la période sélectionnée)',
    'Hourly Accumulation of Viewing Time Across Themes':
      'Nombre d’heures visionnées par jour (accumulé sur la période sélectionnée - par thème)',
    'Cumulative Hours Watched by Time of Day':
      'Heures cumulées de visionnage par heure de la journée',
    'Amount of Hours Watched per Theme per {interval}':
      "Nombre total d'heures regardées par {interval}",
    'Total Amount of Hours Watched per Day':
      'Nombre d’heures visionnées sur une période (accumulé)',
    'Total Time Spent Watching': 'Temps total passé à regarder',
    Total: 'Total',
    'Theme Name': 'Nom du thème',
    'Usage Statistics': 'Statistiques d’utilisation',

    GraphToolTips: {
      ThemeOverADayTable:
        "Le tableau montre le nombre d’heures pendant lesquelles chaque thème a été visionné à des moments spécifiques de la journée. Par exemple, la première colonne montre le nombre d’heures pendant lesquelles chaque thème a été visionné entre 00:00 et 01:00 pendant la période sélectionnée. Notez que le tableau indique le nombre total d'heures dans toutes les unités.",
      ThemeWatchOverADay:
        "Pour chaque heure de la journée, le graphique montre le nombre total d'heures regardées pour tous les thèmes et unités sélectionnés. Par exemple, à 14h00, un total de {heures} heures ont été visionnées pour toutes les unités sélectionnées au cours de la période sélectionnée. Essentiellement, le graphique montre combien d'unités sont utilisées tout au long de la journée.",
      ThemeWatchOverAPeriodTable:
        "Le tableau montre le nombre d'heures pendant lesquelles chaque thème a été regardé par {interval} pour toutes les unités sélectionnées. Essentiellement, il montre combien de thèmes sont regardés au fil du temps.",
      ThemeWatchOverAPeriod:
        'Le graphique montre le nombre total d’heures visionnées sur une base quotidienne pour les unités et thèmes sélectionnés. Comme il s’agit de toutes les unités sélectionnées, le nombre d’heures visionnées peut dépasser 24 heures si plus d’une unité est sélectionnée.',
    },
  },
  da: {
    'Total amount of units': 'Totalt antal enheder',
    Units: 'Enheder',
    'Showing for {unitCount} units and {themeCount} themes':
      'Viser for {unitCount} enheder og {themeCount} temaer',
    'Number of themes watched': 'Antal sete temaer',
    'Themes watched': 'Temaer set',
    'Hours Watched': 'Antal timer set',
    Minutes: 'Minutter',
    Hours: 'Timer',
    Days: 'Dage',
    Weeks: 'Uger',
    Months: 'Måneder',
    Day: 'Dag',
    Week: 'Uge',
    Month: 'Måned',
    Date: 'Dato',
    'Time of Day': 'Tid på dagen',
    'Time Spent Watching Each Theme': 'Tid set per tema',
    'Time Spent Watching': 'Tid set',
    watched: 'set',
    'Hours watched over a day': 'Antal timer set per dag',
    'Hours watched over a period': 'Antal timer set over en period',
    'Start date': 'Start dato',
    'First entry': 'Første indgang',
    'End date': 'Slut dato',
    'Included units': 'Inkluderet enheder',
    'Included themes': 'Inkluderet temaer',
    'Total hours watched (over selected period)':
      'Total antal timer set (over valgte period)',
    'View time per theme (accumulated over selected period)':
      'Tid set per tema (akkumuleret over valgte period)',
    'Hourly Accumulation of Viewing Time Across Themes':
      'Antal timer set over en dag (akkumuleret over valgte period - per tema)',
    'Cumulative Hours Watched by Time of Day':
      'Akkumulerede timer set pr. time på dagen',
    'Amount of Hours Watched per Theme per {interval}':
      'Akkumulerede timer set per {interval}',
    'Total Amount of Hours Watched per Day':
      'Antal timer set over en period (akkumuleret)',
    Total: 'Total',
    'Theme Name': 'Tema navn',
    'Usage Statistics': 'Brugsstatistik',
    'Total Time Spent Watching': 'Samlet visningstid',
    GraphToolTips: {
      ThemeOverADayTable:
        'Tabellen viser antallet af timer, hvert tema er blevet set på specifikke tidspunkter på dagen. For eksempel viser den første kolonne antallet af timer, hvert tema er blevet set mellem 00:00 og 01:00 i den valgte periode. Bemærk, at tabellen viser det samlede antal timer på tværs af alle enheder.',
      ThemeWatchOverADay:
        'For hver time på dagen viser grafen det samlede antal timer, der er set på tværs af alle valgte temaer og enheder. For eksempel er der kl. 14:00 set et samlet antal på {hours} timer alle valgte enheder i tidsperioden. I bund og grund viser grafen, hvor på dagen der er størst forbrug af enhederne.',
      ThemeWatchOverAPeriodTable:
        'Tabellen viser antallet af timer, hvert tema er blevet set per {interval} for alle valgte enheder. I bund og grund viser den, hvor meget temaerne bliver set gennem tiden.',
      ThemeWatchOverAPeriod:
        'Grafen viser det akkumulerede antal timer, der er set på daglig basis for de valgte enheder og temaer. Fordi det er på tværs af alle valgte enheder, kan de sete timer være mere end 24 timer, hvis mere end én enhed er valgt.',
    },
  },
}
