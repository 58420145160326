import { Grid, makeStyles, Typography } from '@material-ui/core'

import { i18nStrings } from 'constants/translations'
import { useStatisticsFilter } from 'context/StatisticsFilterContext'
import { nameFromThemeId } from 'helpers/nameFromThemeId'
import {
  ThemeWatchOverADay,
  ThemeWatchOverAPeriod,
} from 'pages/Home/components'
import { ThemeOverADayTable } from 'pages/Home/components/ThemeOverADayTable'
import { ThemeOverAPeriodTable } from 'pages/Home/components/ThemeOverAPeriodTable'
import { ThemeTable } from 'pages/Home/components/ThemeTable'
import { useCallback } from 'react'

const useStyles = makeStyles((theme: any) => ({
  page: {
    padding: theme.spacing(2),
    height: '100vh',
    // paddingTop: '226px',
    position: 'relative',
    // overflowX: 'hidden',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  item: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    textAlign: 'center',
    width: '100%',
    height: '100%',
  },
}))

type DashBoardProps = {
  totalUnits: number | null
  datasets: {
    themes: readonly {
      readonly id: string
      readonly name: string
      readonly titleObject: Record<string, Record<string, string>>
    }[]
    themesHoursWatchedOverADayTranslated: Record<string, number[]>
    themesHoursWatchedOverAPeriodTranslated: Record<
      string,
      Record<string, number>
    >
    viewTimeByTheme: Record<string, number>
    filteredViewTimeByTheme: {
      name: string
      watched: number
    }[]
  }
  ispdfExport: boolean
  children?: React.ReactNode
}

export const DashboardStatistics = ({
  totalUnits,
  datasets,
  ispdfExport,
  children, // Allows us to add more use-case specific content to the dashboard
}: DashBoardProps) => {
  // Contains numerous graphs and stats shared by /Home and /Stats

  const { language, startDate, endDate, selectedUnits, selectedThemes } =
    useStatisticsFilter()

  const strings = i18nStrings[language]
  const classes = useStyles()

  const filteredByUnitAndThemeText = useCallback(
    () =>
      strings['Showing for {unitCount} units and {themeCount} themes']
        .replace('{unitCount}', `${selectedUnits.length || totalUnits}`)
        .replace(
          '{themeCount}',
          `${
            selectedThemes.length ||
            Object.keys(datasets.viewTimeByTheme).length
          }`,
        ),

    [selectedThemes, selectedUnits, language],
  )

  const totalSecondsWatched = Object.keys(datasets.viewTimeByTheme).reduce(
    (acc, k) => datasets.viewTimeByTheme[k] + acc,
    0,
  )

  const formatViewTime = (totalSeconds: number) => {
    // Format of "{} Days {} Hours {} Minutes"
    const days = Math.floor(totalSeconds / 60 / 60 / 24)
    const hours = Math.floor((totalSeconds / 60 / 60) % 24)
    const minutes = Math.floor((totalSeconds / 60) % 60)

    let formattedString = `${minutes} ${strings['Minutes']}`
    if (hours > 0) {
      formattedString = `${hours} ${strings['Hours']} ${formattedString}`
    }
    if (days > 0) {
      formattedString = `${days} ${strings['Days']} ${formattedString}`
    }

    return formattedString
  }

  const TotalHoursWatchedContent = () => (
    <div className={classes.item}>
      <Typography>{strings['Total amount of units']}</Typography>
      <h1>
        {totalUnits} {strings['Units']}{' '}
        {selectedUnits.length ? `(${selectedUnits.length} selected)` : ''}
      </h1>
    </div>
  )

  const NumberOfThemesWatchedContent = () => (
    <div className={classes.item}>
      <Typography>
        {strings['Number of themes watched']} - {filteredByUnitAndThemeText()}
      </Typography>
      <h1>
        {`${Object.keys(datasets.viewTimeByTheme).length} / ${
          selectedThemes.length || Object.keys(datasets.viewTimeByTheme).length
        }
      `}{' '}
        {strings['Themes watched']}
      </h1>
    </div>
  )

  const HoursWatchedContent = () => (
    <div className={classes.item}>
      <Typography>{strings['Total Time Spent Watching']}</Typography>
      <h1>{formatViewTime(totalSecondsWatched)}</h1>
    </div>
  )

  const HoursWatchByThemeContent = ({
    isPdfExport,
  }: {
    isPdfExport: boolean
  }) => (
    <div className={classes.item}>
      <ThemeTable
        title={`${
          strings['Time Spent Watching Each Theme']
        } - ${filteredByUnitAndThemeText()}`}
        data={datasets.filteredViewTimeByTheme.map((t) => ({
          ...t,
          name: nameFromThemeId(datasets.themes, t.name, language), // Name is ID
        }))}
        language={language}
        timeFormatter={formatViewTime}
        isPdfExport={isPdfExport}
      />
    </div>
  )

  const ThemeOverAdayTableContent = ({
    isPdfExport,
  }: {
    isPdfExport: boolean
  }) => (
    <div className={classes.item} id="hoursWatchedOverADay">
      <ThemeOverADayTable
        title={`${
          strings['Hourly Accumulation of Viewing Time Across Themes']
        } - ${filteredByUnitAndThemeText()}`}
        data={datasets.themesHoursWatchedOverADayTranslated}
        language={language}
        isPdfExport={isPdfExport}
      />
    </div>
  )

  const HoursWatchOverAdayTotalContent = ({
    isPdfExport,
  }: {
    isPdfExport: boolean
  }) => (
    <div className={classes.item} id="hoursWatchedOverADayTotal">
      <ThemeWatchOverADay
        width={1330}
        title={`${
          strings['Cumulative Hours Watched by Time of Day']
        } - ${filteredByUnitAndThemeText()}`}
        language={language}
        themesHoursWatchedOverADay={
          datasets.themesHoursWatchedOverADayTranslated
        }
        isPdfExport={isPdfExport}
        combine
        hideLegend
      />
    </div>
  )

  const ThemeOverAPeriodTableContent = ({
    isPdfExport,
  }: {
    isPdfExport: boolean
  }) => (
    <div className={classes.item} id="themesWatchedOverAPeriod">
      <ThemeOverAPeriodTable
        title={`${
          strings['Amount of Hours Watched per Theme per {interval}']
        } - ${filteredByUnitAndThemeText()}`}
        data={datasets.themesHoursWatchedOverAPeriodTranslated ?? {}}
        language={language}
        startDate={startDate}
        endDate={endDate}
        isPdfExport={isPdfExport}
      />
    </div>
  )

  const ThemeWatchOverAPeriodTotalContent = ({
    isPdfExport,
  }: {
    isPdfExport: boolean
  }) => (
    <div className={classes.item} id="themesWatchedOverAPeriodTotal">
      <ThemeWatchOverAPeriod
        width={1330}
        title={`${
          strings['Total Amount of Hours Watched per Day']
        } - ${filteredByUnitAndThemeText()}`}
        themesHoursWatchedOverAPeriod={
          datasets.themesHoursWatchedOverAPeriodTranslated
        }
        combine
        hideLegend
        isPdfExport={isPdfExport}
        language={language}
        startDate={startDate}
        endDate={endDate}
      />
    </div>
  )

  return ispdfExport ? (
    <>
      <HoursWatchedContent />
      <HoursWatchByThemeContent isPdfExport={true} />
      <ThemeOverAdayTableContent isPdfExport={true} />
      <HoursWatchOverAdayTotalContent isPdfExport={true} />
      <ThemeOverAPeriodTableContent isPdfExport={true} />
      <ThemeWatchOverAPeriodTotalContent isPdfExport={true} />
    </>
  ) : (
    <div className={classes.page}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TotalHoursWatchedContent />
        </Grid>
        <Grid item xs={6}>
          <NumberOfThemesWatchedContent />
        </Grid>
        <Grid item xs={12}>
          <HoursWatchedContent />
        </Grid>
        <Grid item xs={12}>
          <HoursWatchByThemeContent isPdfExport={false} />
        </Grid>
        <Grid item xs={12}>
          <ThemeOverAdayTableContent isPdfExport={false} />
        </Grid>
        <Grid item xs={12}>
          <HoursWatchOverAdayTotalContent isPdfExport={false} />
        </Grid>
        <Grid item xs={12}>
          <ThemeOverAPeriodTableContent isPdfExport={false} />
        </Grid>
        <Grid item xs={12}>
          <ThemeWatchOverAPeriodTotalContent isPdfExport={false} />
        </Grid>
        {children}
      </Grid>
    </div>
  )
}
