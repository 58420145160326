import { makeStyles, useTheme } from '@material-ui/core'
import { CustomRechartsTooltip } from 'components/CustomRechartsTooltip'
import { GraphTitle } from 'pages/CustomColors/GraphTitle'
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    paddingTop: '10px',
  },
}))

interface UnitsByWeekProps {
  unitsByWeek?: readonly number[] | null
  width?: number
}

const UnitsByWeek = ({ unitsByWeek, width }: UnitsByWeekProps) => {
  const classes = useStyles()

  const muiTheme = useTheme()

  return (
    <div className={classes.wrapper}>
      <GraphTitle title="Amount of Units Used per Week" />

      {unitsByWeek ? (
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            margin={{ top: 10, bottom: 0, right: 30, left: 0 }}
            data={unitsByWeek
              .map((n, i) => ({ name: `${i} weeks ago`, count: n }))
              .reverse()}
          >
            <XAxis interval={2} dataKey="name" />
            <YAxis interval={1} />
            <CartesianGrid stroke={muiTheme.palette.primary.light} />

            <Tooltip
              content={<CustomRechartsTooltip y_label="Units Used" />}
            ></Tooltip>

            <Line
              isAnimationActive={false}
              type="monotone"
              dataKey="count"
              stroke="#E09E67"
              name="Total units"
            />
            {/* <Tooltip /> */}
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <p>No data</p>
      )}
    </div>
  )
}

export default UnitsByWeek
