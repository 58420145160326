import { Button, makeStyles, Tooltip, Typography } from '@material-ui/core'
import HelpIcon from '@mui/icons-material/Help'

const useStyles = makeStyles((theme) => ({
  titleArea: {
    marginBottom: '16px',
    display: 'flex',
  },
  title: {
    flex: 1,
    display: 'inline-flex',
    justifyContent: 'center',
  },

  tooltip: {
    marginLeft: 'auto',
    borderRadius: '50%',
    // TODO: how tf do I make this button shade rounded like the table tooltips?
    minWidth: '48px',
  },
  tooltipIcon: {
    color: theme.palette.type === 'light' ? '#0000008A' : '#FFFFFFB3',
  },
}))

export const GraphTitle = ({
  title,
  tooltip,
}: {
  title: string
  tooltip?: string
}) => {
  const classes = useStyles()

  return (
    <div className={classes.titleArea}>
      <div className={classes.title}>
        <Typography>{title}</Typography>
      </div>

      {tooltip && (
        <Button className={classes.tooltip}>
          <Tooltip title={tooltip}>
            <HelpIcon className={classes.tooltipIcon} />
          </Tooltip>
        </Button>
      )}
    </div>
  )
}
