import { Grid, IconButton, makeStyles } from '@material-ui/core'
import DarkMode from '@mui/icons-material/DarkMode'
import LightMode from '@mui/icons-material/LightMode'
import { useTheme } from 'App'
import { useStatisticsFilter } from 'context/StatisticsFilterContext'
import SelectItem from '../../pages/Home/components/SelectItem'

import clsx from 'clsx'
import { Language } from 'constants/languages'
import moment from 'moment'
import ExportButton from 'pages/Home/components/ExportButton'
import SelectSingleItem, {
  ValueLabel,
} from 'pages/Home/components/SelectSingleItem'
import SetDateIntervalButton from 'pages/Home/components/SetDateIntervalButton'
import Calendar from '../../pages/Home/components/Calendar'

const useStyles = makeStyles((theme: any) => ({
  filter: {
    backgroundColor: theme.palette.background.paper,
    opacity: 0.98,
    padding: theme.spacing(2),
    // position: 'fixed',
    width: '100%',
    zIndex: 1000,
    // height: '210px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
  },
  pickDate: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  spreader: {
    margin: '0 auto',
  },
  spacer: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  iconButton: {
    width: '66px',
  },
}))

type FilterHeaderProps = {
  headerClass: string
  unitSelectionData: ValueLabel[]
  themeSelectionData: ValueLabel[]
  addLightModeToggle: boolean
  onExportXlsx: () => void
  onExportPdf: () => void
}

export const FilterHeader = ({
  headerClass,
  unitSelectionData,
  themeSelectionData,
  addLightModeToggle,
  onExportXlsx,
  onExportPdf,
}: FilterHeaderProps) => {
  const { theme: muiTheme, handleThemeChange } = useTheme()

  const classes = useStyles()

  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    creatingPdf,
    creatingXlsx,
    language,
    setLanguage,
    selectedUnits,
    setSelectedUnits,
    selectedThemes,
    setSelectedThemes,
  } = useStatisticsFilter()

  const languageOptions = [
    {
      value: 'en',
      label: 'English',
    },
    {
      value: 'da',
      label: 'Dansk',
    },
    {
      value: 'fr',
      label: 'Français',
    },
  ]

  return (
    <div className={clsx(classes.filter, headerClass)}>
      <div className={classes.pickDate}>
        <Calendar
          label="From (start of day 00.00)"
          date={startDate}
          setDate={(date) => setStartDate(moment(date).startOf('day').toDate())}
        />
        <div className={classes.spacer} />
        <Calendar
          label="To (start of day 00.00)"
          date={endDate}
          setDate={(date) => setEndDate(moment(date).startOf('day').toDate())}
        />

        <SetDateIntervalButton
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
        <div className={classes.spreader} />
        <ExportButton
          onExportClicked={onExportPdf}
          onExportXlsxClicked={onExportXlsx}
          creatingPdf={creatingPdf}
          creatingXlsx={creatingXlsx}
        />

        {addLightModeToggle && (
          <>
            <div className={classes.spacer} />
            <IconButton
              className={classes.iconButton}
              onClick={handleThemeChange}
            >
              {muiTheme.palette.type === 'light' ? <LightMode /> : <DarkMode />}
            </IconButton>
          </>
        )}
        <div className={classes.spacer} />
        <SelectSingleItem
          options={languageOptions}
          selectedItemsOption={{
            value: language,
            label: languageOptions.find((option) => option.value === language)!
              .label,
          }}
          setSelectedItemsOption={(option) =>
            setLanguage(option.value as Language)
          }
        />
      </div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <SelectItem
            label="Units"
            options={unitSelectionData}
            selectedItemsOption={selectedUnits}
            setSelectedItemsOption={setSelectedUnits}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectItem
            label="Themes"
            options={themeSelectionData}
            selectedItemsOption={selectedThemes}
            setSelectedItemsOption={setSelectedThemes}
          />
        </Grid>
      </Grid>
    </div>
  )
}
