import {
  makeStyles,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@material-ui/core'
import HelpIcon from '@mui/icons-material/Help'
import { Language } from 'constants/languages'
import { i18nStrings } from 'constants/translations'
import MaterialTable from 'material-table'
import moment from 'moment'
import { useState } from 'react'

const useStyles = makeStyles({
  select: {
    '& .MuiSelect-select': {
      backgroundColor: 'transparent',
    },
  },

  stickyActionsColumn: {
    '& table:first-child': {
      '& tr': {
        '& td:first-child, th:first-child': {
          position: 'sticky',
          left: 0,
          zIndex: 1,
        },
        '& th:first-child': {
          zIndex: 11,
        },
      },
    },
  },
})

type DateAggregation = 'Day' | 'Week' | 'Month'

export const ThemeOverAPeriodTable = ({
  title,
  data,
  language,
  startDate,
  endDate,
  isPdfExport = false,
}: {
  title: string
  data: Record<string, Record<string, number>>
  language: Language
  startDate: Date | null
  endDate: Date | null
  isPdfExport?: boolean
}) => {
  if (!moment(endDate).isValid || !moment(endDate).isValid)
    return <p>Choose a start and end date</p>

  const days = moment(endDate).diff(moment(startDate), 'days')

  if (days < 1 || isNaN(days)) return <p>Not a valid date</p>

  const strings = i18nStrings[language]

  // We can only realiably show 49 days of data without side-scrolling
  // so we force it to max 49 days of data if creating pdf
  const pdfMaxIntervalCount = 49
  if (isPdfExport) {
    startDate = new Date(
      Math.max(
        moment(endDate).subtract(49, 'days').toDate().getTime(),
        startDate!.getTime(),
      ),
    )
  }

  const [aggregateDatesOn, setAggregateDatesOn] =
    useState<DateAggregation>('Day')

  const dateToDayFormatter = new Intl.DateTimeFormat(language, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  })

  const dateToWeekFormatter = {
    format(date: Date) {
      const day = date.getDay() // Sunday = 0, Monday = 1, ..., Saturday = 6
      const diff = day === 0 ? -6 : 1 - day // Adjust days to get to the nearest Monday
      const nearestMonday = new Date(date)
      nearestMonday.setDate(date.getDate() + diff)
      return dateToDayFormatter.format(nearestMonday)
    },
  }

  const dateToMonthFormatter = {
    format(date: Date) {
      const newDate = new Date(date)
      return dateToDayFormatter.format(newDate.setDate(1))
    },
  }

  const dateFormatters = {
    Day: dateToDayFormatter,
    Week: dateToWeekFormatter,
    Month: dateToMonthFormatter,
  }

  const classes = useStyles()

  const keys = new Set(
    Object.keys(data)
      .map((k) => data[k])
      .map((v) => Object.keys(v))
      .flat(),
  )

  const columnsUnsorted = [...keys].map((themeName) => {
    const aggregatedData: Record<
      string,
      { dateName: string; watched: number }
    > = {}

    ;[...Array(days).keys()].forEach((day) => {
      if (isPdfExport && day >= pdfMaxIntervalCount) {
        return
      }

      const date = moment(startDate).add(day, 'days').toDate()
      // Converts the date to a common date such that aggregation works
      // for the selected DateAggregation Interval
      const dateName = dateFormatters[aggregateDatesOn].format(date)

      if (!aggregatedData[dateName]) {
        aggregatedData[dateName] = { dateName, watched: 0 }
      }

      const dayKey = Math.floor(date.getTime() / 1000 / 60 / 60 / 24)
      aggregatedData[dateName].watched += data[dayKey]?.[themeName] || 0
    })

    const aggregatedArray = Object.values(aggregatedData)

    return {
      name: themeName,
      data: aggregatedArray,
      sumWatched: aggregatedArray.reduce(
        (sum, { watched }) => sum + watched,
        0,
      ),
    }
  })

  const columns = columnsUnsorted.sort((a, b) => b.sumWatched - a.sumWatched)

  const muiTheme = useTheme()

  const colorscale = [
    `${muiTheme.palette.text.primary}08`,
    'rgba(253, 239, 179)',
    'rgb(253, 237, 176)',
    'rgb(249, 198, 139)',
    'rgb(244, 159, 109)',
    'rgb(234, 120, 88)',
    'rgb(218, 83, 82)',
    'rgb(191, 54, 91)',
    'rgb(158, 35, 98)',
    'rgb(120, 26, 97)',
    'rgb(83, 22, 84)',
    'rgb(47, 15, 61)',
  ]

  const maxValue = Math.max(
    ...columns.map((d) => Math.max(...d.data.flatMap((d) => d.watched))),
  )

  if (columns.length === 0) return <p>Not enough data</p>

  return (
    <div className={classes.stickyActionsColumn}>
      <MaterialTable
        title={
          <Typography>
            {title.replace('{interval}', strings[aggregateDatesOn])}
          </Typography>
        }
        columns={[
          {
            field: 'name',
            title: (
              <div style={{ display: 'fixed', width: 200 }}>
                {strings['Theme Name']}
              </div>
            ),
            width: '50%',
          },
          {
            field: 'watched',
            width: '50%',
            title: (
              <div
                style={{
                  height: 26,
                  width: '100%',
                  display: 'flex',
                  transform: `translateY(20px)`,
                  marginTop: 6,
                  marginBottom: 12,
                  alignItems: 'flex-end',
                }}
              >
                {columns[0].data.map(({ dateName }, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        minWidth: 20,
                        maxWidth: 20,
                        minHeight: 20,
                        maxHeight: 20,
                        paddingRight: 4,
                        marginBottom: 4,
                      }}
                    >
                      {i % 7 === 0 && (
                        <div
                          style={{
                            color: muiTheme.palette.text.primary,
                            fontSize: 10,
                            width: 120,
                            marginLeft: 4,
                          }}
                        >
                          {dateName}
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            ),
            render: (rowData) => (
              <div
                style={{
                  width: '100%',
                  height: 25,
                  borderRadius: '6px',
                  display: 'flex',
                }}
              >
                {rowData.data.map(({ watched }, i) => {
                  const gradientIndex = Math.min(
                    Math.ceil(
                      (Math.round(watched * 10) / (maxValue * 10)) *
                        (colorscale.length - 1),
                    ),
                    colorscale.length - 1,
                  )

                  return (
                    <div
                      key={i}
                      style={{
                        minWidth: 20,
                        maxWidth: 20,
                        minHeight: 20,
                        maxHeight: 20,
                        paddingRight: 2,
                        paddingLeft: 2,
                        position: 'relative',
                      }}
                    >
                      <div
                        key={i}
                        style={{
                          borderRadius: '4px',
                          minWidth: 18,
                          maxWidth: 18,
                          minHeight: 18,
                          maxHeight: 18,
                          backgroundColor: colorscale[gradientIndex],
                          marginBottom: 4,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {gradientIndex !== 0 && (
                          <div
                            style={{
                              color: gradientIndex > 5 ? 'white' : '#00000099',
                              fontWeight: 'bold',
                              fontSize: 10,
                            }}
                          >
                            {Math.round(watched)}
                          </div>
                        )}
                      </div>
                      {i % 7 === 0 && (
                        <div
                          style={{
                            borderLeft: '1px solid',
                            transform: 'scaleY(2)',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            bottom: 0,
                          }}
                        />
                      )}
                    </div>
                  )
                })}
              </div>
            ),
          },
        ]}
        options={{
          search: !isPdfExport,
          paging: !isPdfExport,
          pageSize: 10,
          pageSizeOptions: [10, 20, 30, 50, 100, 150, 200],
          sorting: false,
          exportButton: !isPdfExport,
          padding: 'dense',
        }}
        data={columns}
        actions={[
          {
            icon: () => (
              <Select
                className={classes.select}
                value={aggregateDatesOn}
                onChange={(val) =>
                  setAggregateDatesOn(val.target.value as DateAggregation)
                }
              >
                <MenuItem value={'Day'}>{strings['Days']}</MenuItem>
                <MenuItem value={'Week'}>{strings['Weeks']}</MenuItem>
                <MenuItem value={'Month'}>{strings['Months']}</MenuItem>
              </Select>
            ),
            isFreeAction: true,
            hidden: isPdfExport,
            onClick: () => {},
          },
          {
            icon: () => <HelpIcon />,
            tooltip: strings.GraphToolTips[
              'ThemeWatchOverAPeriodTable'
            ].replace(
              '{interval}',
              strings[aggregateDatesOn].toLocaleLowerCase(),
            ),
            isFreeAction: true,
            onClick: () => {},
            hidden: isPdfExport,
          },
        ]}
      />
    </div>
  )
}
