import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { useAuth } from 'hooks'

const useStyles = makeStyles((theme: any) => ({
  root: {
    height: '100vh',
  },
  image: {
    // backgroundImage: 'url(https://source.unsplash.com/random)',
    // backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.grey[900],
    // backgroundSize: 'cover',
    // backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#E09E67',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#8F4F4D',
    '&:hover': {
      backgroundColor: '#975757',
    },
  },
}))

export default function SignInSide() {
  const classes = useStyles()
  const { signInWithGoogle } = useAuth()

  return (
    <>
      <Grid container component="main" className={classes.root}>
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
          style={{ backgroundColor: '#424242' }}
        >
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon style={{ color: 'black' }} />
            </Avatar>
            <Typography component="h1" variant="h5" style={{ color: 'white' }}>
              Sign in
            </Typography>
            <form className={classes.form} noValidate>
              <Button
                onClick={signInWithGoogle}
                fullWidth
                variant="contained"
                className={classes.submit}
              >
                <p style={{ color: 'white' }}>Sign In with Google</p>
              </Button>
            </form>
          </div>
        </Grid>
      </Grid>
    </>
  )
}
