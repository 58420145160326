import { makeStyles } from '@material-ui/core'
import graphql from 'babel-plugin-relay/macro'
import moment from 'moment'
import { Suspense, useEffect, useMemo } from 'react'
import {
  fetchQuery,
  PreloadedQuery,
  usePreloadedQuery,
  useQueryLoader,
  UseQueryLoaderLoadQueryOptions,
  useRelayEnvironment,
} from 'react-relay'

import { statisticsToXlsx } from 'helpers/xlsxExportHelper'

import { useParams } from 'react-router-dom'

import { useMutation } from 'hooks/useMutation'

import { TopBar } from 'SimpleLayout'
import Loader from 'components/Loader'
import { DashboardStatistics } from 'components/statistics/DashboardStatistics'
import { FilterHeader } from 'components/statistics/FilterHeader'
import { i18nStrings } from 'constants/translations'
import {
  StatisticsFilterProvider,
  useStatisticsFilter,
} from 'context/StatisticsFilterContext'
import {
  calculateFilteredViewTimeByTheme,
  getThemesHoursWatchedOverADayTranslated,
  getThemesHoursWatchedOverAPeriodTranslated,
} from 'helpers/statisticsDatasetHelpers'
import { toPdf } from 'helpers/toPdf'
import {
  StatsCustomerLogStastisticsQuery,
  StatsCustomerLogStastisticsQueryVariables,
} from './__generated__/StatsCustomerLogStastisticsQuery.graphql'
import { StatsPageViewMutation } from './__generated__/StatsPageViewMutation.graphql'

const useStyles = makeStyles((theme: any) => ({
  item: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  header: {
    width: '100%',
    alignItems: 'center',
  },
  pdfTitle: {
    fontSize: '40px',
    fontWeight: 'bold',
    marginBottom: '10px',
    marginTop: '10px',
    textAlign: 'center',
  },
  pdfSubtitle: {
    fontSize: '30px',
    marginBottom: '10px',
    marginTop: '10px',
    textAlign: 'center',
  },
}))

const statsCustomerLogStastisticsQuery = graphql`
  query StatsCustomerLogStastisticsQuery($input: CustomerLogStatisticsInput!) {
    getCustomerLogStastistics(input: $input) {
      totalUnits
      viewTimeByTheme
      themesHoursWatchedOverADay
      themesHoursWatchedOverAPeriod
      wavecareBoxes
      themes
      customerStatisticsName
    }
  }
`

const statsPageViewMutation = graphql`
  mutation StatsPageViewMutation($input: PageViewInput!) {
    pageView(input: $input) {
      status
    }
  }
`

const StatsContainer = () => {
  const [statsQueryReference, loadStatsQuery] =
    useQueryLoader<StatsCustomerLogStastisticsQuery>(
      statsCustomerLogStastisticsQuery,
    )
  const { customerStatisticsId } = useParams<{ customerStatisticsId: string }>()

  const pageView = useMutation<StatsPageViewMutation>(statsPageViewMutation)
  const viewPage = async () => {
    await pageView({
      customerStatisticsId,
    })
  }

  useEffect(() => {
    if (!customerStatisticsId) return

    loadStatsQuery(
      {
        input: {
          customerStatisticsId,
          themeIds: [],
          unitIds: [],
          startDate: moment().subtract(3, 'months').toDate(),
          endDate: new Date(),
        },
      },
      { fetchPolicy: 'network-only' },
    )
  }, [])

  return (
    <Suspense fallback={<Loader />}>
      {statsQueryReference && (
        <StatisticsFilterProvider>
          <Stats
            statsQueryReference={statsQueryReference}
            loadStatsQuery={loadStatsQuery}
            viewPage={viewPage}
          />
        </StatisticsFilterProvider>
      )}
    </Suspense>
  )
}

const Stats = (p: {
  statsQueryReference: PreloadedQuery<StatsCustomerLogStastisticsQuery>
  loadStatsQuery: (
    variables: StatsCustomerLogStastisticsQueryVariables,
    options?: UseQueryLoaderLoadQueryOptions | undefined,
  ) => void
  viewPage: () => Promise<void>
}) => {
  const {
    getCustomerLogStastistics: {
      themesHoursWatchedOverAPeriod,
      themesHoursWatchedOverADay,
      viewTimeByTheme,
      totalUnits,
      themes: rawThemes,
      wavecareBoxes,
      customerStatisticsName,
    },
  } = usePreloadedQuery(statsCustomerLogStastisticsQuery, p.statsQueryReference)

  const themes = rawThemes as readonly {
    readonly id: string
    readonly name: string
    readonly titleObject: Record<string, Record<string, string>>
  }[]

  const { customerStatisticsId } = useParams<{ customerStatisticsId: string }>()
  const {
    startDate,
    endDate,
    setCreatingPdf,
    setCreatingXlsx,
    language,
    selectedUnits,
    selectedThemes,
  } = useStatisticsFilter()

  const strings = i18nStrings[language]

  const classes = useStyles()
  const environment = useRelayEnvironment()

  useEffect(() => {
    p.viewPage()
  }, [])

  useEffect(() => {
    const variables = {
      input: {
        customerStatisticsId,
        unitIds: selectedUnits.map((x) => x.value),
        themeIds: selectedThemes.map((x) => x.value),
        startDate,
        endDate,
      },
    }
    // https://relay.dev/docs/next/guided-tour/refetching/refreshing-queries/
    // setIsRefreshing(true)
    fetchQuery(
      environment,
      statsCustomerLogStastisticsQuery,
      variables,
    ).subscribe({
      complete: () => {
        // setIsRefreshing(false)
        p.loadStatsQuery(variables, { fetchPolicy: 'store-and-network' })
      },
      error: () => {
        // setIsRefreshing(false)
      },
    })
  }, [selectedUnits, selectedThemes, startDate, endDate, language])

  const exportXlsx = async () => {
    setCreatingXlsx(true)
    await statisticsToXlsx({
      language,
      startDate,
      endDate,
      themesHoursWatchedOverAPeriod,
      selectedUnits,
      totalUnits,
      selectedThemes,
      themes,
      themesHoursWatchedOverADayTranslated,
      viewTimeByTheme,
    })
    setCreatingXlsx(false)
  }

  const exportPdf = async () => {
    setCreatingPdf(true)
    await toPdf({ elementId: 'pdf-export', language })
    setCreatingPdf(false)
  }

  const filteredViewTimeByTheme = useMemo(
    () => calculateFilteredViewTimeByTheme(viewTimeByTheme),
    [viewTimeByTheme],
  )

  const themesHoursWatchedOverADayTranslated =
    getThemesHoursWatchedOverADayTranslated(
      themes,
      themesHoursWatchedOverADay,
      language,
    )

  const themesHoursWatchedOverAPeriodTranslated =
    getThemesHoursWatchedOverAPeriodTranslated(
      themes,
      themesHoursWatchedOverAPeriod,
      language,
    )

  const dashboardStatisticsProps = {
    totalUnits,
    datasets: {
      themes,
      themesHoursWatchedOverADayTranslated,
      themesHoursWatchedOverAPeriodTranslated,
      viewTimeByTheme,
      filteredViewTimeByTheme,
      themesHoursWatchedOverADay,
      themesHoursWatchedOverAPeriod,
    },
  }

  const dateFormatter = new Intl.DateTimeFormat(language, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  })

  return (
    <div>
      <TopBar title={customerStatisticsName} />

      <FilterHeader
        headerClass={classes.header}
        unitSelectionData={(
          wavecareBoxes as {
            id: string
            name: string
            unitNumber: number
            placedAt: string
          }[]
        ).map((box) => ({
          value: box.id,
          label: `${box.name} (#${box.unitNumber}) - ${box.placedAt}`,
        }))}
        themeSelectionData={themes.map((theme) => ({
          value: theme.id,
          label: theme.name,
        }))}
        addLightModeToggle={true}
        onExportXlsx={exportXlsx}
        onExportPdf={exportPdf}
      />

      <DashboardStatistics {...dashboardStatisticsProps} ispdfExport={false} />

      {/* We set display to None to essentially hide the pdf-export until we generate pdf*/}
      <div
        id="pdf-export"
        style={{
          width: '1400px',
          display: 'none',
        }}
      >
        <div className={classes.item}>
          <p className={classes.pdfTitle}>
            {customerStatisticsName} - {strings['Usage Statistics']}
          </p>
          <p className={classes.pdfSubtitle}>
            {dateFormatter.format(startDate!)} -{' '}
            {dateFormatter.format(endDate!)}
          </p>
        </div>

        <DashboardStatistics {...dashboardStatisticsProps} ispdfExport={true} />
      </div>
    </div>
  )
}

export default StatsContainer
