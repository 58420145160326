import { makeStyles, useTheme } from '@material-ui/core'
import { CustomRechartsTooltip } from 'components/CustomRechartsTooltip'
import { Language } from 'constants/languages'
import { i18nStrings } from 'constants/translations'
import moment from 'moment'
import { GraphTitle } from 'pages/CustomColors/GraphTitle'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    paddingTop: '10px',
  },
  title: {
    marginBottom: '24px',
  },
}))

interface ThemeWatchOverAPeriodProps {
  width?: number
  themesHoursWatchedOverAPeriod: Record<string, Record<string, number>>
  title: string
  combine?: boolean
  hideLegend?: boolean
  language: Language
  startDate: Date | null
  endDate: Date | null
  isPdfExport?: boolean
}

const ThemeWatchOverAPeriod = ({
  width,
  themesHoursWatchedOverAPeriod,
  title,
  combine = false,
  hideLegend = false,
  language,
  startDate,
  endDate,
  isPdfExport = false,
}: ThemeWatchOverAPeriodProps) => {
  const classes = useStyles()

  const muiTheme = useTheme()
  const strings = i18nStrings[language]

  if (!moment(endDate).isValid || !moment(endDate).isValid)
    return <p>Choose a start and end date</p>

  const days = moment(endDate).diff(moment(startDate), 'days')

  if (days < 1 || isNaN(days)) return <p>Not a valid date</p>

  const keys = new Set(
    Object.keys(themesHoursWatchedOverAPeriod)
      .map((k) => themesHoursWatchedOverAPeriod[k])
      .map((v) => Object.keys(v))
      .flat(),
  )

  const formatter = new Intl.DateTimeFormat(language, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  })

  const data = days
    ? [...Array(days).keys()].map((day) => {
        const date = moment(startDate).add(day, 'days').toDate()
        const dayKey = Math.floor(date.getTime() / 1000 / 60 / 60 / 24)

        const obj = {
          name: formatter.format(new Date(date)),
        } as Record<string, any>
        keys.forEach((themeName) => {
          if (combine) {
            obj.combined =
              (obj.combined ?? 0) +
              (themesHoursWatchedOverAPeriod[dayKey]?.[themeName] || 0)
          } else {
            obj[themeName] =
              themesHoursWatchedOverAPeriod[dayKey]?.[themeName] || 0
          }
        })
        return obj
      })
    : []

  return (
    <div className={classes.wrapper}>
      <GraphTitle
        title={title}
        tooltip={
          isPdfExport
            ? undefined
            : strings.GraphToolTips['ThemeWatchOverAPeriod']
        }
      />

      {days ? (
        <ResponsiveContainer width={isPdfExport ? width : '100%'} height={300}>
          <LineChart
            margin={{
              top: 10,
              bottom: 0,
              right: 30,
              left: 0,
            }}
            data={data}
          >
            <CartesianGrid stroke={muiTheme.palette.primary.light} />
            <XAxis interval={Math.round(days / 12)} dataKey="name" />
            <YAxis interval={1} />
            <RechartsTooltip
              content={
                <CustomRechartsTooltip
                  x_label={strings['Date']}
                  y_label={strings['Hours Watched']}
                />
              }
            />{' '}
            {Object.keys(data[0])
              .filter((n) => n !== 'name')
              .sort((a, b) => a.localeCompare(b))
              .map((themeName, i) => (
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  dataKey={themeName}
                  stroke={RANDOM_COLORS[i % RANDOM_COLORS.length]}
                  name={themeName}
                  dot={false}
                />
              ))}
            {!hideLegend && <Legend />}
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <p>No data</p>
      )}
    </div>
  )
}

export default ThemeWatchOverAPeriod

const RANDOM_COLORS = [
  '#FF6633',
  '#FFB399',
  '#FF33FF',
  '#FFFF99',
  '#00B3E6',
  '#E6B333',
  '#3366E6',
  '#999966',
  '#99FF99',
  '#B34D4D',
  '#80B300',
  '#809900',
  '#E6B3B3',
  '#6680B3',
  '#66991A',
  '#FF99E6',
  '#CCFF1A',
  '#FF1A66',
  '#E6331A',
  '#33FFCC',
  '#66994D',
  '#B366CC',
  '#4D8000',
  '#B33300',
  '#CC80CC',
  '#66664D',
  '#991AFF',
  '#E666FF',
  '#4DB3FF',
  '#1AB399',
  '#E666B3',
  '#33991A',
  '#CC9999',
  '#B3B31A',
  '#00E680',
  '#4D8066',
  '#809980',
  '#E6FF80',
  '#1AFF33',
  '#999933',
  '#FF3380',
  '#CCCC00',
  '#66E64D',
  '#4D80CC',
  '#9900B3',
  '#E64D66',
  '#4DB380',
  '#FF4D4D',
  '#99E6E6',
]
