import { Button, makeStyles } from '@material-ui/core'
import graphql from 'babel-plugin-relay/macro'
import Loader from 'components/Loader'
import { useMutation } from 'hooks/useMutation'
import { Suspense, useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay'
import { MobileAppMutation } from './__generated__/MobileAppMutation.graphql'
import {
  MobileAppQuery,
  MobileAppQueryResponse,
} from './__generated__/MobileAppQuery.graphql'
import { ThemeCard } from './components'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 8,
  },
  kanbanContainer: {
    display: 'flex',
  },
  uploadButton: {
    margin: 4,
  },
  cardContainerWrapper: {
    display: 'flex',
  },
  column: {
    flexDirection: 'column',
    margin: 8,
  },
  dropzone: {
    width: '470px',
    borderRadius: 4,
    padding: 8,
    minHeight: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}))

const mobileAppKanbanBoardQuery = graphql`
  query MobileAppQuery($input: String!) {
    mobileAppKanban {
      id
      columns
    }
    themesByType(input: $input) {
      id
      name
      categories
      signedThumbnailUrl
    }
  }
`

const mobileAppKanbanMutation = graphql`
  mutation MobileAppMutation($input: UpdateMobileAppKanbanInput!) {
    updateMobileAppKanban(input: $input) {
      mobileAppKanban {
        id
        columns
      }
    }
  }
`

type Columns = {
  [key: string]: {
    order: number
    title: string
    themeIds: string[]
  }
}

const mapDataToColumns = (
  mobileAppKanban: Columns,
  themes: MobileAppQueryResponse['themesByType'],
): Columns => {
  const themeIdsInColumns = Object.values(mobileAppKanban)
    .map((column) => column.themeIds)
    .flat()
  const themeIdsNotInColumns = themes
    .map((item) => item.id)
    .filter((key) => !themeIdsInColumns.includes(key))
  const newColumns = JSON.parse(JSON.stringify(mobileAppKanban))
  newColumns.Inbox.themeIds = [
    ...new Set([...newColumns.Inbox.themeIds, ...themeIdsNotInColumns]),
  ]
  return newColumns
}

const MobileAppContainer = () => {
  const [mobileAppKanbanBoardQueryReference, loadMobileAppQuery] =
    useQueryLoader<MobileAppQuery>(mobileAppKanbanBoardQuery)

  useEffect(() => {
    loadMobileAppQuery(
      {
        input: 'mobile',
      },
      { fetchPolicy: 'network-only' },
    )
  }, [])

  return (
    <Suspense fallback={<Loader />}>
      {mobileAppKanbanBoardQueryReference && (
        <MobileApp
          mobileAppKanbanBoardQueryReference={
            mobileAppKanbanBoardQueryReference
          }
        />
      )}
    </Suspense>
  )
}

type MobileAppProps = {
  mobileAppKanbanBoardQueryReference: PreloadedQuery<
    MobileAppQuery,
    Record<string, unknown>
  >
}

const MobileApp = ({ mobileAppKanbanBoardQueryReference }: MobileAppProps) => {
  const { mobileAppKanban, themesByType } = usePreloadedQuery(
    mobileAppKanbanBoardQuery,
    mobileAppKanbanBoardQueryReference,
  )
  const updateMobileAppKanban = useMutation<MobileAppMutation>(
    mobileAppKanbanMutation,
  )

  const [columns, setColumns] = useState<Columns>(mobileAppKanban.columns)

  const classes = useStyles()

  useEffect(() => {
    if (!themesByType) return
    setColumns(mapDataToColumns(mobileAppKanban.columns, themesByType))
  }, [themesByType, setColumns])

  const onDragEnd = (result: any, columns: Columns, setColumns: any) => {
    if (!result.destination) return
    const { source, destination } = result
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId]
      const destColumn = columns[destination.droppableId]
      const sourceItems = [...sourceColumn.themeIds]
      const destItems = [...destColumn.themeIds]
      const [removed] = sourceItems.splice(source.index, 1)
      destItems.splice(destination.index, 0, removed)
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          themeIds: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          themeIds: destItems,
        },
      })
    } else {
      const column = columns[source.droppableId]
      const copiedItems = [...column.themeIds]
      const [removed] = copiedItems.splice(source.index, 1)
      copiedItems.splice(destination.index, 0, removed)
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          themeIds: copiedItems,
        },
      })
    }
  }

  const onPublishToProductionClicked = async () => {
    await updateMobileAppKanban({
      id: mobileAppKanban.id,
      columns: columns,
    })
  }

  const didMakeChanges =
    JSON.stringify(mobileAppKanban.columns) !== JSON.stringify(columns)

  return (
    <div className={classes.container}>
      <div className={classes.uploadButton}>
        <Button
          disabled={!didMakeChanges}
          onClick={onPublishToProductionClicked}
          variant="outlined"
        >
          Publish to production
        </Button>
      </div>
      <DragDropContext
        onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
      >
        <div className={classes.kanbanContainer}>
          {columns &&
            Object.entries(columns)
              .sort((a, b) => a[1].order - b[1].order)
              .map(([columnId, column]) => {
                return (
                  <Droppable key={columnId} droppableId={columnId}>
                    {(provided) => (
                      <div className={classes.column}>
                        <h1>{column.title}</h1>
                        <div
                          className={classes.dropzone}
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {column.themeIds?.map((themeId, index) => {
                            const theme = themesByType.find(
                              (t) => t.id === themeId,
                            )
                            if (!theme) return null
                            return (
                              <Draggable
                                key={theme.id}
                                draggableId={theme.id}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <ThemeCard key={theme.id} {...theme} />
                                  </div>
                                )}
                              </Draggable>
                            )
                          })}
                          {provided.placeholder}
                        </div>
                      </div>
                    )}
                  </Droppable>
                )
              })}
        </div>
      </DragDropContext>
    </div>
  )
}

export default MobileAppContainer
