import { Typography, useTheme } from '@material-ui/core'
import { Language } from 'constants/languages'
import { i18nStrings } from 'constants/translations'
import MaterialTable from 'material-table'

type DataType = {
  name: string
  watched: number
}[]

export const ThemeTable = ({
  title,
  data,
  language,
  timeFormatter,
  isPdfExport = false,
}: {
  title: string
  data: DataType
  language: Language
  timeFormatter: (totalSeconds: number) => string
  isPdfExport?: boolean
}) => {
  if (!data) return null

  const strings = i18nStrings[language]

  const maxValue = Math.max(...data.map((d) => d.watched))

  const muiTheme = useTheme()

  return (
    <>
      <MaterialTable
        title={<Typography>{title}</Typography>}
        columns={[
          {
            field: 'name',
            title: strings['Theme Name'],
            cellStyle: { width: '30%', maxWidth: '30%' },
          },
          {
            field: 'watched',
            cellStyle: { width: '30%', maxWidth: '30%' },

            title: strings['Time Spent Watching'],
            render: (rowData) => timeFormatter(rowData.watched * 3600),
          },
          {
            field: 'watched',
            title: '',
            cellStyle: { width: '40%', maxWidth: '40%' },
            render: (rowData) => (
              <div
                style={{
                  width: '100%',
                  height: 20,
                  backgroundColor: muiTheme.palette.primary.light,
                  borderRadius: '6px',
                }}
              >
                <div
                  style={{
                    borderRadius: '6px',
                    width: `${(rowData.watched / maxValue) * 100}%`,
                    height: '100%',
                    backgroundColor: '#E09E67',
                  }}
                />
              </div>
            ),
          },
        ]}
        options={{
          search: !isPdfExport,
          paging: !isPdfExport,
          pageSize: isPdfExport ? data.length : 10,
          pageSizeOptions: [10, 20, 30, 50, 100, 150, 200],
          sorting: false,
          exportButton: !isPdfExport,
          headerStyle: { backgroundColor: 'transparent' },
        }}
        style={{ backgroundColor: 'transparent' }}
        data={data}
      />
    </>
  )
}
